import { Dispatch, SetStateAction } from "react";
import {
  IBillingPositionDTO,
  useDefaultBillingPosition,
} from "../../Interfaces/Bill";
import { useGetAllServicesQuery } from "../../redux/apiSlices/Service";
import FormRow from "../Forms/FormUtils/FormRow";
import { convertServiceToBillingPosition } from "./BillingPosition.utils";
import { useOrganizationTax } from "../../Hooks/useOrganizationTax";

type BillingPositionServiceSelectProps = {
  billingPosition: IBillingPositionDTO;
  setBillingPosition: Dispatch<SetStateAction<IBillingPositionDTO>>;
};
export default function BillingPositionServiceSelect({
  billingPosition,
  setBillingPosition,
}: BillingPositionServiceSelectProps) {
  const { data: services } = useGetAllServicesQuery();
  const defaultBillingPosition = useDefaultBillingPosition();
  const { organizationTax } = useOrganizationTax();
  const handleServiceSelectChange = (id: number) => {
    if (!services) return;

    const service = services.find((service) => service.id === id);

    if (service) {
      
      let billingPosition: IBillingPositionDTO =
        convertServiceToBillingPosition(service, organizationTax);
      setBillingPosition(billingPosition);
    } else {
      setBillingPosition(defaultBillingPosition);
    }
  };
  return (
    <FormRow
      label="Leistung auswählen"
      placeholder="Leistung auswählen"
      value={billingPosition.originId}
      onChange={handleServiceSelectChange}
      type="select"
      selectOptions={{
        titleKey: "title",
        valueKey: "id",
        options: services ?? [],
        multiple: false,
      }}
    />
  );
}
