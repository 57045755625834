import React from "react";
import { cn } from "../../utils/cn";

const VerticalDivider = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("h-3 w-[1px] bg-black rounded-full", className)}
    {...props}
  />
));

export { VerticalDivider };
