import { Dispatch, SetStateAction } from "react";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import {
  BillingPositionOrginType,
  IBillingPositionDTO
} from "../../Interfaces/Bill";
import FormRow from "../Forms/FormUtils/FormRow";
import { useBillingPositionQuantityFormRow } from "./BillingPositionQuantityFormRow.hooks";

type BillingPositionQuantityFormRowProps = {
  billingPosition: IBillingPositionDTO;
  setBillingPosition: Dispatch<SetStateAction<IBillingPositionDTO>>;
};
export default function BillingPositionQuantityFormRow({
  billingPosition,
  setBillingPosition,
}: BillingPositionQuantityFormRowProps) {
  const { units } = useBills();
  const { selectedProduct } =
    useBillingPositionQuantityFormRow(billingPosition);

  return (
    <div className="flex flex-row w-full gap-2">
      <FormRow
        label="Stückzahl"
        step=".25"
        value={billingPosition.quantity}
        onChange={(quantity) => {
          if (parseFloat(quantity) >= 0) {
            setBillingPosition((old) => ({
              ...old,
              quantity: parseFloat(quantity),
            }));
          }
        }}
        placeholder="Stückzahl"
        type="number"
        //error={error.quantityError}
        //errorMessage={"Bitte geben Sie eine Stückzahl an"}
      />
      {billingPosition.originType === BillingPositionOrginType.Product &&
        selectedProduct &&
        selectedProduct.count &&
        (billingPosition?.quantity ?? 0) > selectedProduct?.count && (
          <span className="label" style={{ color: "#FF9494" }}>
            Hinweis: Es sind nur {selectedProduct?.count} Stück verfügbar.
          </span>
        )}

      <FormRow
        onChange={(unitId) => {
          setBillingPosition((old) => ({ ...old, unitId: unitId }));
        }}
        label="Einheit"
        type="select"
        placeholder="Einheit"
        value={billingPosition.unitId}
        selectOptions={{
          options: units,
          titleKey: "title",
          valueKey: "id",
          multiple: false,
        }}
      />
    </div>
  );
}
