import { useEffect, useState } from "react";
import { IProductDTO, useProducts } from "../../Contexts/ProductContext/ProductProvider";
import { BillingPositionOrginType, IBillingPositionDTO } from "../../Interfaces/Bill";

export const useBillingPositionQuantityFormRow = (billingPosition: IBillingPositionDTO) => {
    const { products } = useProducts();
    const [selectedProduct, setSelectedProduct] = useState<IProductDTO>();
  
    useEffect(() => {
      if (billingPosition.originType === BillingPositionOrginType.Product) {
        setSelectedProduct(
          products.find((product) => product.id === billingPosition.originId)
        );
      }
    }, [billingPosition.originType]);

    return {
        selectedProduct
    }
};