import { useEffect } from "react";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { DiscountType, IBillingPositionDTO } from "../../Interfaces/Bill";
import { VerticalDivider } from "../Divider/VerticalDivider";
import BillingPositionBrutto from "./BillingPositionRow/BillingPositionBrutto";
import BillingPositionNetto from "./BillingPositionRow/BillingPositionNetto";
import BillingPositionTax from "./BillingPositionRow/BillingPositionTax";
import BillingPositionUnit from "./BillingPositionRow/BillingPositionUnit";

type BillingPositionPreviewProps = {
  billingPosition: IBillingPositionDTO;
};

export default function BillingPositionPreview({
  billingPosition,
}: BillingPositionPreviewProps) {
  const { units } = useBills();

  useEffect(() => {
    console.log("preview billing position", billingPosition);
  }, [billingPosition]);

  return (
    <>
      {billingPosition.title && billingPosition.title.length > 0 && (
        <div className={"flex flex-col"}>
          <div className="flex items-center gap-2">
            <div className={"w-1 h-4 rounded-tr-xl rounded-br-xl bg-brand"} />
            <span className="font-semibold">{billingPosition.title}</span>
          </div>
          <div className="text-sm flex items-center gap-2 ml-3">
            <BillingPositionNetto
              isSummaryPosition={billingPosition.isSummaryPosition}
              subpositions={billingPosition.subPositions}
              netto={billingPosition.netto}
            />
            <BillingPositionTax
              tax={billingPosition.tax}
              isSummaryPosition={billingPosition.isSummaryPosition}
            />
            <VerticalDivider />
            <BillingPositionBrutto
              isSummaryPosition={billingPosition.isSummaryPosition}
              subpositions={billingPosition.subPositions}
              brutto={billingPosition.brutto}
            />
            <VerticalDivider />
            <BillingPositionUnit
              quantity={billingPosition.quantity}
              unitId={billingPosition.unitId}
            />
            {billingPosition.hasDiscount === true && (
              <>
                <VerticalDivider />
                <span>
                  Rabatt:{" "}
                  {billingPosition.discount!.toFixed(2).replace(".", ",")}&nbsp;
                  {billingPosition.discountType === DiscountType.FixedValue &&
                    "€"}
                  {billingPosition.discountType === DiscountType.Percentage &&
                    "%"}
                </span>
              </>
            )}
          </div>
          {billingPosition.subPositions &&
            billingPosition.subPositions?.length > 0 &&
            billingPosition.subPositions.map(
              (subPosition: IBillingPositionDTO) => (
                <div className="ml-4">
                  <BillingPositionPreview
                    billingPosition={subPosition}
                    key={subPosition.originId}
                  />
                </div>
              )
            )}
        </div>
      )}
    </>
  );
}
