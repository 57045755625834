import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAuthQuery } from "../baseAuthQuery";
import { IServiceDTO } from "../../Interfaces/Service";
import { ITaskDTO } from "../../Interfaces/Task";
import { ITaskServiceCategory } from "../../Interfaces/TaskServiceCategory";
import { TravelApi } from "./Travel";

export const ServiceApi = createApi({
  reducerPath: "ServiceApi",
  baseQuery: baseAuthQuery,
  tagTypes: ["Service", "ServiceCategory"],
  endpoints: (builder) => ({
    addService: builder.mutation({
      query: (data: IServiceDTO) => ({
        url: "/Service",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Service" }],
    }),
    getAllServices: builder.query<IServiceDTO[], void>({
      query: () => ({
        url: "/Service",
      }),
      providesTags: (result) =>
        result && result.length > 0
          ? result.map(({ id }) => ({ type: "Service", id }))
          : [{ type: "Service" }],
    }),
    updateService: builder.mutation({
      query: (data: IServiceDTO) => ({
        url: "/Service",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Service", id: arg.id },
      ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(TravelApi.util.invalidateTags([{ type: "Travel" }]));
      },
    }),
    billServices: builder.mutation<IServiceDTO[], IServiceDTO[]>({
      query: (data: IServiceDTO[]) => ({
        url: "/Service/billServices",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) =>
        result && result.length > 0
          ? result.map(({ id }) => ({ type: "Service", id }))
          : [{ type: "Service" }],
    }),
    deleteService: builder.mutation({
      query: (data: IServiceDTO) => ({
        url: "/Service",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Service", id: arg.id },
      ],
    }),
    startServiceToTask: builder.mutation<IServiceDTO, ITaskDTO>({
      query: (task: ITaskDTO) => ({
        url: "/service/startservicetotask",
        method: "POST",
        body: task,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Service" }],
    }),
    stopServiceToTask: builder.mutation<IServiceDTO, ITaskDTO>({
      query: (task: ITaskDTO) => ({
        url: "/service/stopservicetotask",
        method: "POST",
        body: task,
      }),
      invalidatesTags: (result, error, arg) =>
        result ? [{ type: "Service", id: result.id }] : [{ type: "Service" }],
    }),
    getTaskServiceCategories: builder.query<ITaskServiceCategory[], void>({
      query: () => ({
        url: "/taskServiceCategory",
      }),
      providesTags: (result) =>
        result && result.length > 0
          ? result.map(({ id }) => ({ type: "ServiceCategory", id }))
          : [{ type: "ServiceCategory" }],
    }),
  }),
});

export const {
  useAddServiceMutation,
  useGetAllServicesQuery,
  useUpdateServiceMutation,
  useBillServicesMutation,
  useDeleteServiceMutation,
  useStartServiceToTaskMutation,
  useStopServiceToTaskMutation,
  useGetTaskServiceCategoriesQuery,
} = ServiceApi;
