import { useState } from "react";
import { IBillingPositionDTO } from "../../Interfaces/Bill";
import { Input } from "../UI/Input";
import { Label } from "../UI/Label";
import { useHandleBillingDetailsChange } from "./BillingPositionForm.hooks";
import { useHandleTravelBillingDetailsChange } from "./TravelBillingDetails.hooks";

type TravelBillingDetailsProps = {
  billingPosition: IBillingPositionDTO;
  setBillingPosition: React.Dispatch<React.SetStateAction<IBillingPositionDTO>>;
};

export default function TravelBillingDetails({
  billingPosition,
  setBillingPosition,
}: TravelBillingDetailsProps) {
  const { handleBruttoChange, handleNettoChange, handleTaxChange } =
    useHandleBillingDetailsChange(billingPosition, setBillingPosition);
  const [costPerKm, setCostPerKm] = useState<number | undefined>(0.5);
  const { handleCostPerKmChange } = useHandleTravelBillingDetailsChange(
    billingPosition,
    setBillingPosition,
    setCostPerKm
  );

  return (
    <div className="flex flex-row gap-2">
      <div>
        <Label>Pro KM</Label>
        <Input
          type="number"
          placeholder="Pro KM"
          onChange={handleCostPerKmChange}
          value={costPerKm}
          onFocus={(e) => e.target.select()}
        />
      </div>
      <div>
        <Label>Netto</Label>
        <Input
          type="number"
          placeholder="Nettobetrag"
          onChange={handleNettoChange}
          value={billingPosition.netto}
          onFocus={(e) => e.target.select()}
        />
      </div>
      <div>
        <Label>Steuer</Label>
        <Input
          type="number"
          placeholder="Steuer"
          onChange={handleTaxChange}
          value={billingPosition.tax}
          onFocus={(e) => e.target.select()}
        />
      </div>
      <div>
        <Label>Brutto</Label>
        <Input
          type="number"
          placeholder="Bruttobetrag"
          onChange={handleBruttoChange}
          value={billingPosition.brutto}
          onFocus={(e) => e.target.select()}
        />
      </div>
    </div>
  );
}
