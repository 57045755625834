import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAuthQuery } from "../baseAuthQuery";
import { buildQueries } from "@testing-library/react";
import { IDocument } from "../../Contexts/DocumentContext/DocumentContext";

export const DocumentApi = createApi({
  reducerPath: "DocumentApi",
  baseQuery: baseAuthQuery,
  tagTypes: ["Documents"],
  endpoints: (builder) => ({
    getDocumentById: builder.query<IDocument, number>({
      query: (id) => ({
        url: `/document/download64/${id}`,
      }),
    }),
  }),
});

export const { useGetDocumentByIdQuery } = DocumentApi;
