import { useSnackbar } from "notistack";
import {
  BillingPositionOrginType,
  IBillingPositionDTO,
} from "../../Interfaces/Bill";
import {
  BillingPositionApi,
  useAddBillingPositionMutation,
  useDeleteBillingPositionMutation,
  useDissolveSummaryPositionMutation,
  useUpdateBillingPositionMutation,
  useUpdateSummaryPositionMutation,
} from "../apiSlices/BillingPosition.slice";
import { RTKQueryErrorHandler } from "./utils";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { ServiceApi } from "../apiSlices/Service";
import { useDispatch } from "react-redux";
import { TravelApi } from "../apiSlices/Travel";
import { useProducts } from "../../Contexts/ProductContext/ProductProvider";

export const useHandleAddBillingPosition = () => {
  const [addBillingPosition, { isLoading, isError, error, isSuccess }] =
    useAddBillingPositionMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { updateBillingPosition } = useBills();

  const handleAddBillingPosition = async (
    billingPosition: IBillingPositionDTO
  ) => {
    let x = enqueueSnackbar("Position wird erstellt", { variant: "default" });
    try {
      const result = await addBillingPosition(billingPosition).unwrap();
      if (updateBillingPosition) {
        updateBillingPosition(result);
      }
      closeSnackbar(x);
      enqueueSnackbar("Position erfolgreich erstellt", { variant: "success" });
    } catch (err) {
      closeSnackbar(x);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return {
    handleAddBillingPosition,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};

export const useHandleUpdateBillingPosition = () => {
  const [updateBillingPosiiton, { isLoading, isError, error, isSuccess }] =
    useUpdateBillingPositionMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { invalidateDependentTags } = useInvalidateDependentTags();
  const handleUpdateBillingPosition = async (
    billingPosition: IBillingPositionDTO
  ) => {
    let x = enqueueSnackbar("Position wird bearbeitet", { variant: "default" });

    try {
      const result = await updateBillingPosiiton(billingPosition).unwrap();
      closeSnackbar(x);
      invalidateDependentTags(billingPosition);
      enqueueSnackbar("Position erfolgreich bearbeitet", {
        variant: "success",
      });
    } catch (err) {
      closeSnackbar(x);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return {
    handleUpdateBillingPosition,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};

export const useHandleUpdateSummaryPosition = () => {
  const [updateSummaryPosition, { isLoading, isError, error, isSuccess }] =
    useUpdateSummaryPositionMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleUpdateSummaryPosition = async (
    billingPosition: IBillingPositionDTO
  ) => {
    let x = enqueueSnackbar("Position wird bearbeitet", { variant: "default" });
    try {
      const result = await updateSummaryPosition(billingPosition).unwrap();
      closeSnackbar(x);
      enqueueSnackbar("Position erfolgreich bearbeitet", {
        variant: "success",
      });
    } catch (err) {
      closeSnackbar(x);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return {
    handleUpdateSummaryPosition,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};

export const useHandleDeleteBillingPosition = () => {
  const [deleteBillingPosition, { isLoading, isError, error, isSuccess }] =
    useDeleteBillingPositionMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { invalidateDependentTags } = useInvalidateDependentTags();
  const handleDeleteBillingPosition = async (
    billingPosition: IBillingPositionDTO
  ) => {
    let x = enqueueSnackbar("Position wird entfernt", { variant: "default" });

    try {
      await deleteBillingPosition(billingPosition.id!).unwrap();
      closeSnackbar(x);
      invalidateDependentTags(billingPosition);
      enqueueSnackbar("Position erfolgreich entfernt", { variant: "success" });
    } catch (err) {
      closeSnackbar(x);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return {
    handleDeleteBillingPosition,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};

export const useHandleDissolveSummaryPosition = () => {
  const [dissolveSummaryPosition, { isLoading, isError, error, isSuccess }] =
    useDissolveSummaryPositionMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleDissolveSummaryPosition = async (
    billingPosition: IBillingPositionDTO
  ) => {
    let x = enqueueSnackbar("Gesamtposition wird aufgelöst", {
      variant: "default",
    });
    try {
      await dissolveSummaryPosition(billingPosition.id!).unwrap();
      closeSnackbar(x);
      enqueueSnackbar("Gesamtposition erfolgreich aufgelöst", {
        variant: "success",
      });
    } catch (err) {
      closeSnackbar(x);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return {
    handleDissolveSummaryPosition,
    isSuccess,
    isLoading,
    isError,
    error,
  }
};

const useInvalidateDependentTags = () => {
  const dispatch = useDispatch();
  const { fetchProducts } = useProducts();
  const invalidateDependentTags = (billingPosition: IBillingPositionDTO) => {
    if (billingPosition.originType === BillingPositionOrginType.Service) {
      dispatch(
        ServiceApi.util.invalidateTags([
          { type: "Service", id: billingPosition.originId },
        ])
      );
    }
    if (billingPosition.originType === BillingPositionOrginType.Travel) {
      dispatch(
        TravelApi.util.invalidateTags([
          { type: "Travel", id: billingPosition.originId },
        ])
      );
    }
    if (billingPosition.originType === BillingPositionOrginType.Product) {
      fetchProducts();
    }

    BillingPositionApi.util.invalidateTags([
      { type: "BillingPosition", id: billingPosition.id },
    ]);
  };

  return { invalidateDependentTags };
};
