import { useState } from "react";
import styles from "./BillingPosition.module.css";

import { IconButton } from "@mui/material";
import { ChevronDown, ChevronUp } from "lucide-react";
import {
  DiscountType,
  IBillDTO,
  IBillingPosition,
  IBillingPositionDTO,
} from "../../Interfaces/Bill";
import {
  useHandleDeleteBillingPosition,
  useHandleDissolveSummaryPosition,
} from "../../redux/requestHandlers/BillingPosition.handler";
import MoreOptionsButton from "../Buttons/MoreOptionsButton/MoreOptionsButton";
import { VerticalDivider } from "../Divider/VerticalDivider";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../UI/Collapsible";
import BillingPositionFormDialog from "./BillingPositionFormDialog";
import BillingPositionBrutto from "./BillingPositionRow/BillingPositionBrutto";
import BillingPositionNetto from "./BillingPositionRow/BillingPositionNetto";
import BillingPositionTax from "./BillingPositionRow/BillingPositionTax";
import BillingPositionUnit from "./BillingPositionRow/BillingPositionUnit";

function BillingPositionRow({
  bill,
  billingPosition,
  billingPositions,
  setBillingPositions,
  backgroundColor,
  generatePdf,
  getOpenServicesOfCustomerId,
  isOffer,
}: {
  bill: IBillDTO | undefined;
  billingPosition: IBillingPositionDTO;
  billingPositions: any;
  setBillingPositions: any;
  backgroundColor?: string;
  generatePdf: () => void;
  getOpenServicesOfCustomerId?: () => void;
  isOffer: boolean;
}) {
  const [open, setOpen] = useState<boolean>(false);

  const onPressEdit = () => {
    setOpen(true);
  };

  const { handleDeleteBillingPosition } = useHandleDeleteBillingPosition();
  const onPressDelete = async () => {
    await handleDeleteBillingPosition(billingPosition);
    generatePdf();
    setBillingPositions(
      billingPositions.filter((bp: IBillingPosition) => {
        if (bp.id === billingPosition.id) {
          return false;
        }
        return true;
      })
    );
    if (getOpenServicesOfCustomerId !== undefined) {
      getOpenServicesOfCustomerId();
    }
  };

  const { handleDissolveSummaryPosition } = useHandleDissolveSummaryPosition();
  const onPressDissolve = async () => {
    await handleDissolveSummaryPosition(billingPosition);
    generatePdf();
  };

  const options = [
    {
      text: "Bearbeiten",
      function: onPressEdit,
    },
    {
      text: "Löschen",
      function: onPressDelete,
    },
    ...(billingPosition.isSummaryPosition
      ? [
          {
            text: "Gesamtposition auflösen",
            function: onPressDissolve,
          },
        ]
      : []),
  ];

  const onSubmitEdit = (tempBillingPosition: IBillingPosition) => {
    let tempBillingPositions = billingPositions;

    tempBillingPositions = tempBillingPositions.map(
      (tempBP: IBillingPosition) => {
        if (tempBP.id === billingPosition.id) {
          return tempBillingPosition;
        }
        return tempBP;
      }
    );

    setBillingPositions(tempBillingPositions);
  };
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(true);
  return (
    <Collapsible open={isCollapsibleOpen} onOpenChange={setIsCollapsibleOpen}>
      <div
        className="flex row"
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: backgroundColor ?? "transparent",
        }}
      >
        <div
          className={"flex column ".concat(styles.container)}
          style={{ backgroundColor: backgroundColor ?? "transparent" }}
        >
          <header className="flex centered-aligned stretched-justify">
            <div className="flex centered-aligned" style={{ gap: "6px" }}>
              <div className={styles.accent} />
              <span className="bold-big">{billingPosition.title}</span>
            </div>
          </header>
          <section
            className="label flex centered-aligned"
            style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}
          >
            <BillingPositionNetto
              isSummaryPosition={billingPosition.isSummaryPosition}
              subpositions={billingPosition.subPositions}
              netto={billingPosition.netto}
            />
            <BillingPositionTax
              tax={billingPosition.tax}
              isSummaryPosition={billingPosition.isSummaryPosition}
            />
            <VerticalDivider />
            <BillingPositionBrutto
              isSummaryPosition={billingPosition.isSummaryPosition}
              subpositions={billingPosition.subPositions}
              brutto={billingPosition.brutto}
            />
            <VerticalDivider />
            <BillingPositionUnit
              quantity={billingPosition.quantity}
              unitId={billingPosition.unitId}
            />
            {billingPosition.hasDiscount === true && (
              <>
                <VerticalDivider />
                <span>
                  Rabatt:{" "}
                  {billingPosition.discount!.toFixed(2).replace(".", ",")}{" "}
                  {billingPosition.discountType === DiscountType.FixedValue &&
                    " €"}{" "}
                  {billingPosition.discountType === DiscountType.Percentage &&
                    " %"}
                </span>
              </>
            )}
          </section>
        </div>
        <div className="flex flex-row items-center">
          {billingPosition.isSummaryPosition && (
            <CollapsibleTrigger className="mr-3">
              <IconButton>
                {isCollapsibleOpen ? <ChevronUp /> : <ChevronDown />}
              </IconButton>
            </CollapsibleTrigger>
          )}
          <MoreOptionsButton options={options} />
        </div>
      </div>
      <BillingPositionFormDialog
        billId={bill?.id ?? 0}
        initialBillingPosition={billingPosition}
        isOpen={open}
        setIsOpen={setOpen}
        isOffer={isOffer}
      />
      {billingPosition.subPositions &&
        billingPosition.subPositions.length > 0 && (
          <CollapsibleContent className="ml-4">
            {billingPosition.subPositions.map(
              (subPosition: IBillingPositionDTO) => {
                return (
                  <BillingPositionRow
                    key={subPosition.id}
                    billingPosition={subPosition}
                    bill={bill}
                    billingPositions={billingPositions}
                    setBillingPositions={setBillingPositions}
                    backgroundColor={backgroundColor}
                    generatePdf={generatePdf}
                    getOpenServicesOfCustomerId={getOpenServicesOfCustomerId}
                    isOffer={isOffer}
                  />
                );
              }
            )}
          </CollapsibleContent>
        )}
    </Collapsible>
  );
}

export default BillingPositionRow;
