import { Dispatch, SetStateAction, useEffect, useState } from "react";

//Icons
import { AccessTime, CalendarMonth, Euro, Person } from "@mui/icons-material";
import { Checkbox, Collapse, FormControlLabel } from "@mui/material";
import dayjs from "dayjs";
import { IPosition, useBills } from "../../Contexts/BillContext/BillProvider";
import { useStaff } from "../../Contexts/StaffContext/StaffProvider";
import { serviceFormModes } from "../../Pages/Services/Services";
import { BillingPositionOrginType } from "../../Interfaces/Bill";

interface PositionFullRowProps {
  position: IPosition;
  backgroundColor?: string;
  selectedPositions?: Array<number>;
  setSelectedPositions?: Dispatch<SetStateAction<Array<number>>>;
  setFormMode?: Dispatch<SetStateAction<serviceFormModes>>;
  isInnerPosition?: boolean;
  index?: number;
  setSelectedServiceIds: Dispatch<SetStateAction<Array<number>>>;
  selectedServiceIds: Array<number>;
}

//TODO: fix data structure of position, too many null values, missing values, services seem to not always have a serviceId, replace tempId
function PositionFullRow({
  position: service,
  backgroundColor,
  selectedPositions,
  setSelectedPositions,
  setFormMode,
  isInnerPosition = false,
  index,
  setSelectedServiceIds,
  selectedServiceIds,
}: PositionFullRowProps) {
  const { units } = useBills();
  const { staff } = useStaff();

  const [gesamt, setGesamt] = useState<number>(0);

  useEffect(() => {
    let sum = 0;
    service.positions?.forEach((position: IPosition) => {
      sum += position.netto! * position.quantity!;
    });
    setGesamt(sum);
  }, [service.positions]);

  const checkService = () => {
    console.log("check service called", service);
    if (!selectedPositions?.includes(service.tempId!) && setSelectedPositions) {
      setSelectedPositions((old: Array<number>) => [...old, service.tempId!]);
    } else if (setSelectedPositions) {
      setSelectedPositions((old: Array<number>) =>
        old.filter((entry: number) => entry !== service.tempId)
      );
    }

    if (service.positions && service.positions.length > 0) {
      service.positions.forEach((position: IPosition) => {
        if (position.originType === BillingPositionOrginType.Service) {
          if (selectedServiceIds.includes(position.originId)) {
            setSelectedServiceIds((old: Array<number>) =>
              old.filter((entry: number) => entry !== position.originId)
            );
          } else {
            let newServiceIds = [...selectedServiceIds, position.originId];
            setSelectedServiceIds(newServiceIds);
          }
        }
      });
    }
  };

  return (
    <div
      className={"flex column"}
      style={{ backgroundColor: backgroundColor ?? "transparent" }}
    >
      <header style={{ display: "flex", flexDirection: "row" }}>
        <span style={{ width: "40%", display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <>
                {!isInnerPosition && (
                  <Checkbox
                    color="secondary"
                    checked={selectedPositions?.includes(service.tempId!)}
                    onChange={checkService}
                  />
                )}
              </>
            }
            label={
              (index ?? -1) >= 0
                ? index! + 1 + ". " + service.title
                : service.title
            }
          />
        </span>
        <span
          style={{
            width: "15%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {service.from && (
            <>
              <CalendarMonth fontSize="small" />
              {" " + dayjs(service.from).format("DD.MM.YYYY")}
            </>
          )}
        </span>
        <span style={{ width: "15%", display: "flex", flexDirection: "row" }}>
          {service?.netto ? (
            <>
              <Euro fontSize="small" />
              {`${service.netto} € / ${
                units.find((u) => u.id === service.unitId)?.title ?? `Stk.`
              }`}
            </>
          ) : (
            <>{` Gesamt: ${gesamt?.toFixed(2)} €`}</>
          )}
        </span>
        <span
          style={{
            width: "15%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {service.quantity ? (
            <>
              <AccessTime fontSize="small" />
              {service.quantity}{" "}
              {units.find((unit) => unit.id === service.unitId)?.title ??
                units.find((unit) => unit.id === 0)?.title}
            </>
          ) : (
            <></>
          )}
        </span>
        <span
          style={{
            width: "15%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <>
            {service.userId ? (
              <>
                <Person fontSize="small" />
                {staff.find((s) => s.id === service.userId)?.username}
              </>
            ) : (
              <></>
            )}
          </>
        </span>
      </header>
      <Collapse in={true}>
        <section style={{ paddingLeft: "4%", fontSize: "14px" }}>
          {service.positions?.map((innerPosition: IPosition, index: number) => {
            return (
              <>
                <PositionFullRow
                  key={index}
                  selectedServiceIds={selectedServiceIds}
                  setSelectedServiceIds={setSelectedServiceIds}
                  position={innerPosition}
                  isInnerPosition={true}
                  index={index}
                />
              </>
            );
          })}
        </section>
      </Collapse>
    </div>
  );
}

export default PositionFullRow;
