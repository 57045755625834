import { AxiosResponse } from "axios"; // Assuming you're using axios
import React, { createContext, useContext, useState } from "react";
import useAuthConnection from "../../Hooks/useAuthConnection";

// Create a context
const BillContext = createContext<{
  generatePdf: (billId: number) => Promise<any>; // Type the function
  setDocument: (document: any) => void;
  document: any;
} | null>(null); 

// Create a provider component
export const BillProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [document, setDocument] = useState<any>(null);
  const connection = useAuthConnection();

  const generatePdf = (billId: number) => {
    return new Promise(function (resolve, reject) {
      if (
        billId !== 0
      ) {
        connection
          .post(`/bill/generatepdf`, { id: billId })
          .then((res: AxiosResponse) => {
            setDocument(res.data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  const value = { generatePdf, document, setDocument }; // Value to be provided

  return <BillContext.Provider value={value}>{children}</BillContext.Provider>;
};

// Custom hook to consume the context
export const useBillContext = () => {
  const context = useContext(BillContext);
  if (context === null) {
    throw new Error("useBillContext must be used within a BillProvider");
  }
  return context;
};
