import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAuthQuery } from "../baseAuthQuery";
import { IBillingPositionDTO } from "../../Interfaces/Bill";

export const BillingPositionApi = createApi({
  reducerPath: "BillingPositionApi",
  baseQuery: baseAuthQuery,
  tagTypes: ["BillingPosition"],
  endpoints: (builder) => ({
    addBillingPosition: builder.mutation<
      IBillingPositionDTO,
      IBillingPositionDTO
    >({
      query: (data: IBillingPositionDTO) => ({
        url: "/BillingPosition",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BillingPosition", id: arg.billId },
      ],
    }),
    getBillingPositionsForBill: builder.query<IBillingPositionDTO[], number>({
      query: (billId: number) => ({
        url: `/BillingPosition/${billId}`,
        method: "GET",
      }),
      providesTags: (result, error, billId) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "BillingPosition" as const,
                id: id,
              })),
              { type: "BillingPosition" as const, id: billId },
            ]
          : [{ type: "BillingPosition" as const, id: billId }],
    }),
    updateBillingPosition: builder.mutation({
      query: (data: IBillingPositionDTO) => ({
        url: "/BillingPosition",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BillingPosition", id: arg.id },
        { type: "BillingPosition", id: arg.billId }, // Invalidate the bill's billing positions
      ],
    }),
    updateSummaryPosition: builder.mutation({
      query: (data: IBillingPositionDTO) => ({
        url: "/BillingPosition/summaryPosition",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BillingPosition", id: arg.id },
        { type: "BillingPosition", id: arg.billId },
      ]
    }),
    deleteBillingPosition: builder.mutation({
      query: (id: number) => ({
        url: `/BillingPosition/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BillingPosition", id: arg },
      ],
    }),
    dissolveSummaryPosition: builder.mutation({
      query: (id: number) => ({
        url: `/BillingPosition/summaryPosition/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BillingPosition", id: arg },
      ]
    })
  }),
});

export const {
  useAddBillingPositionMutation,
  useGetBillingPositionsForBillQuery,
  useUpdateBillingPositionMutation,
  useUpdateSummaryPositionMutation,
  useDeleteBillingPositionMutation,
  useDissolveSummaryPositionMutation,
} = BillingPositionApi;
