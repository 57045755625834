import { SetStateAction } from "react";
import { IBillingPositionDTO } from "../../Interfaces/Bill";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import FormRow from "../Forms/FormUtils/FormRow";
import BillingPositionBillingDetailsFormRow from "./BillingPositionBillingDetailsFormRow";
import BillingPositionDiscountFormRow from "./BillingPositionDiscountFormRow";
import { useBillingPositionForm } from "./BillingPositionForm.hooks";
import BillingPositionPreview from "./BillingPositionPreview";
import BillingPositionProductSelect from "./BillingPositionProductSelect";
import BillingPositionQuantityFormRow from "./BillingPositionQuantityFormRow";
import BillingPositionServiceSelect from "./BillingPositionServiceSelect";
import BillingPositionTravelSelect from "./BillingPositionTravelSelect";
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored";

type BillingPositionFormProps = {
  billId: number;
  initialBillingPosition?: IBillingPositionDTO;
  setDialogOpen: React.Dispatch<SetStateAction<boolean>>;
};

export default function BillingPositionForm({
  billId,
  initialBillingPosition,
  setDialogOpen,
}: BillingPositionFormProps) {
  const {
    billingPosition,
    setBillingPosition,
    handleSubmit,
    isLoading,
    isEditMode,
    titleError
  } = useBillingPositionForm(initialBillingPosition, billId, setDialogOpen);

  return (
    <div className="flex flex-col gap-2">
      {!isEditMode && (
        <>
          <BillingPositionServiceSelect
            billingPosition={billingPosition}
            setBillingPosition={setBillingPosition}
          />
          <BillingPositionProductSelect
            billingPosition={billingPosition}
            setBillingPosition={setBillingPosition}
          />
          <BillingPositionTravelSelect
            billingPosition={billingPosition}
            setBillingPosition={setBillingPosition}
          />
        </>
      )}
      <FormRow
        autofocus
        label="Bezeichnung"
        value={billingPosition.title}
        onChange={(title) => setBillingPosition((old) => ({ ...old, title }))}
        placeholder="Bezeichnung eingeben"
        type="text"
        error={titleError}
        errorMessage={InputFieldErrorMessages.TitleError}
      />
      <FormRow
        label="Beschreibung"
        value={billingPosition.description}
        onChange={(description) =>
          setBillingPosition((old) => ({ ...old, description }))
        }
        placeholder="Beschreibung..."
        type="multiline"
      />
      <BillingPositionBillingDetailsFormRow
        billingPosition={billingPosition}
        setBillingPosition={setBillingPosition}
      />
      <BillingPositionQuantityFormRow
        billingPosition={billingPosition}
        setBillingPosition={setBillingPosition}
      />
      <div className="mt-4 mb-8">
        <BillingPositionDiscountFormRow
          billingPosition={billingPosition}
          setBillingPosition={setBillingPosition}
        />
      </div>
      {billingPosition.title && billingPosition.title.length > 0 && (
        <>Vorschau:</>
      )}
      <BillingPositionPreview billingPosition={billingPosition} />
      <CTAButton
        title={isEditMode ? "Speichern" : "Erstellen"}
        isLoading={isLoading}
        onClickHandler={handleSubmit}
      />
    </div>
  );
}
