import {
  Assignment,
  Check,
  Download,
  MonetizationOn,
  Refresh,
  Upload,
} from "@mui/icons-material";
import { Button, LinearProgress, Tooltip } from "@mui/material";
import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { createWorker } from "tesseract.js";
import PdfViewer from "../../Components/PDF/all-pages";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { useCompanyData } from "../../Contexts/CompanyDataContext/CompanyDataProvider";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import {
  IDocument,
  downloadFile,
  useDocuments,
} from "../../Contexts/DocumentContext/DocumentContext";
import useAuthConnection from "../../Hooks/useAuthConnection";
import { base64PdfToImage } from "../../Hooks/Utilities/Printer";
import {
  BillingPositionOrginType,
  IBillDTO,
  IBillingPosition,
} from "../../Interfaces/Bill";
import CreateBillModal from "../BillCards/CreateBillModal";
import Card from "../Card/Card";
import { processInvoice } from "../OCRScanner/regexUtils";
import { optimizeImage } from "../OCRScanner/rergexqVisitenkarte";

interface props {
  type: "Offer" | "Receipt" | "Bill";
  object: IBillDTO | undefined;
  setObject: Dispatch<SetStateAction<IBillDTO | undefined>>;
  document: any;
  setDocument: Dispatch<any>; // Change the type to match the type of `document`
  billingPositions: Array<IBillingPosition>; // Add this line
  setBillingPositions: Dispatch<SetStateAction<Array<IBillingPosition>>>; // Add this line
  setGenerateTasksOpen?: Dispatch<SetStateAction<boolean>>; // Add this line if applicable
  setFile?: Dispatch<SetStateAction<File | undefined>>;
}

const BillPdfViewerCard = ({
  object,
  setObject,
  document,
  setDocument,
  type,
  setGenerateTasksOpen,
  billingPositions,
  setBillingPositions,
  setFile,
}: props) => {
  const { markBillCreated, updateBill } = useBills();
  const { companyData } = useCompanyData();

  const [downloading, setDownloading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  //Added for OCR-Scanning
  const [imageData, setImageData] = useState<string>("");
  const [ocrText, setOcrText] = useState<string>("");
  const { companies, createCompany } = useContacts();
  const [ocrProgress, setOcrProgress] = useState<number>(0);

  const worker = createWorker({
    logger: (m: any) => {
      console.log(m);
      if (m.status == "recognizing text") {
        const progress = Math.round(m.progress * 100);
        setOcrProgress(progress);
      }
    },
  });

  const handleDownloadClick = () => {
    if (object?.isCreated || type === "Offer") {
      download();
    } else {
      setOpen(true);
    }
  };

  const connection = useAuthConnection();

  const generatePdf = (bill?: IBillDTO) => {
    return new Promise(function (resolve, reject) {
      let x = bill ?? object;
      if (
        x?.id !== undefined &&
        x.id !== null &&
        x.id !== 0 &&
        x.isReceipt === false
      ) {
        connection
          .post(`/bill/generatepdf`, { id: x?.id })
          .then((res: AxiosResponse) => {
            setDocument!(res.data);
            resolve(res.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  };

  const download = () => {
    if (object?.document !== undefined) {
      setDownloading(true);
      downloadFile(
        {
          id: object.document,
          title: object.billPrefix + "-" + object.billNumber + ".pdf",
        },
        `/document/download/${object?.document}`,
        (event: any) => {
          const progress = Math.round((event.loaded / event.total) * 100);
          setProgress(progress);
        }
      ).then(() => {
        setDownloading(false);
      });
    }
  };

  const handleCreateBillAndDownload = () => {
    setOpen(false);

    generatePdf().then((res: any) => {
      download();
    });

    if (markBillCreated) {
      markBillCreated({ ...object, isOffer: false });
      setObject!((old: any) => ({ ...old, isCreated: true }));
    }
  };

  function handleImageChange(e: any) {
    const tempFile = e.target.files[0];

    if (tempFile) {
      if (setFileAndUpload) {
        setFileAndUpload(tempFile);
      }

      //convertPngToPdf(imageDataUri);
      blobToBase64(tempFile).then((b64) => {
        setDocument((old: IDocument) => ({ ...old, base64: b64 }));
      });

      console.log(setFile !== undefined, tempFile);

      setOcrProgress(0);
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageDataUri = reader.result as string;
        setImageData(imageDataUri);

        if (tempFile.size > 2000000) {
          optimizeImage(tempFile);
        }
        //convertPngToPdf(imageDataUri);
        blobToBase64(tempFile).then((b64) => {
          setDocument((old: IDocument) => ({ ...old, base64: b64 }));
          if (b64) {
            base64PdfToImage(b64 + "").then((res) => {
              setImageData(res);
              convertImageToText(res);
            });
          }
        });
      };
      reader.readAsDataURL(tempFile);
    }
  }

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const convertImageToText = async (data: any) => {
    if (!data) return;

    setOcrText("Reading Picture");
    await worker.load();
    await worker.loadLanguage("deu");
    await worker.initialize("deu");
    await worker.setParameters({});
    const {
      data: { text },
    } = await worker.recognize(data);
    setOcrText(text);

    //Uses the regexUtils file to get the properties
    const processedInvoice = processInvoice(text, companyData?.id, {
      companies,
      createCompany,
    });

    setBillDTO(processedInvoice, text);
  };

  function setBillDTO(invoice: any, ocrText: any) {
    //If there is no Date found then the current date is set
    if (invoice.RechnungsDatum == null) {
      let currentDate = new Date();
      invoice.RechnungsDatum = currentDate;
    }

    setObject((prev: any) => ({
      ...prev,
      customerId: invoice.Name.id,
      netto: parseFloat(invoice.Netto.replace(",", ".")),
      isPaid: false,
      date: invoice.Rechnungsdatum,
    }));
    setBillingPositions([
      {
        id: 0,
        billId: 0,
        title: "Gesamt",
        description: "",
        netto: parseFloat(invoice.Netto.replace(",", ".")),
        tax: parseFloat(invoice.Steuer ?? 0),
        quantity: 1,
        originId: 0,
        originType: BillingPositionOrginType.None,
      },
    ]);
    connection
      .post("/bill/billingposition", {
        id: 0,
        billId: object?.id,
        title: "Gesamt",
        description: "",
        netto: parseFloat(invoice.Netto.replace(",", ".")),
        tax: parseFloat(invoice.Steuer ?? 0),
        quantity: 1,
        originId: 0,
        originType: BillingPositionOrginType.None,
      })
      .then((res: AxiosResponse) => {})
      .catch((reason: any) => {
        console.log(reason);
      });
  }

  const inputRef = useRef<any>();

  const { addDocument } = useDocuments();
  const setFileAndUpload = async (file: File) => {
    const result = await addDocument(file);

    if (updateBill) {
      updateBill({ ...object, document: result.data.id });
    }
  };

  return (
    <>
      <Card
        bodyPadding={false}
        fullHeightBody={true}
        style={{ backgroundColor: "var(--light-contrast)", height: "100%" }}
      >
        <div
          style={{
            display: "flex",
            paddingLeft: "1em",
            paddingRight: "1em",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "sticky",
            height: "3.5em",
            border: "solid 0px #000000",
            top: 0,
            paddingTop: "0em",
            borderBottomWidth: "1px",
            zIndex: 2,
            backgroundColor: "var(--light-contrast)",
          }}
        >
          <Button
            disabled={object?.id === undefined}
            variant="text"
            style={{ color: "black", borderColor: "black" }}
            onClick={() => {
              generatePdf();
            }}
          >
            {
              <>
                Refresh <Refresh />
              </>
            }
          </Button>
          {type === "Receipt" && (
            <>
              <Button
                /*disabled={object?.id === undefined}*/ onClick={() => {
                  inputRef.current.click();
                }}
                variant="text"
                style={{ color: "black", borderColor: "black" }}
              >
                Hochladen <Upload />
                <input
                  ref={inputRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*, application/pdf"
                  onChange={handleImageChange}
                />
              </Button>
            </>
          )}
          <Button
            disabled={object?.id === undefined}
            variant="text"
            style={{ color: "black", borderColor: "black" }}
            onClick={handleDownloadClick}
          >
            {!downloading && (
              <>
                Herunterladen <Download />
              </>
            )}
            {downloading && <span>{progress} %</span>}
          </Button>
          {(type === "Bill" || type === "Receipt") && (
            <Button
              disabled={object?.id === undefined || object?.isCreated !== true}
              variant="text"
              style={{
                color: object?.isPaid ? "green" : "black",
                borderColor: object?.isPaid ? "green" : "black",
              }}
              onClick={() => {
                if (updateBill) {
                  updateBill({
                    ...object,
                    isPaid: true,
                    isOffer: false,
                    paymentDate: new Date(),
                  });
                  setObject!({
                    ...object,
                    isPaid: true,
                    paymentDate: new Date(),
                  });
                }
              }}
            >
              {
                <>
                  Bezahlt <MonetizationOn />
                </>
              }
            </Button>
          )}
          {type === "Offer" && (
            <>
              <Button
                disabled={object?.id === undefined || object?.isCreated}
                variant="text"
                style={{
                  color: object?.isCreated ? "green" : "black",
                  borderColor: object?.isCreated ? "green" : "black",
                }}
                onClick={() => {
                  if (updateBill) {
                    updateBill({
                      ...object,
                      isCreated: true,
                      isOffer: type === "Offer",
                      isReceipt: false,
                    });
                    setObject!({ ...object, isCreated: true });
                  }
                }}
              >
                {
                  <>
                    Angenommen <Check />
                  </>
                }
              </Button>
              <Tooltip title="Demnächst verfügbar">
                <Button
                  disabled={
                    object?.id === undefined || object?.tasksGenerated === true
                  }
                  variant="text"
                  style={{
                    color: object?.tasksGenerated ? "green" : "black",
                    borderColor: object?.tasksGenerated ? "green" : "black",
                  }}
                  onClick={() => {
                    console.log("setGenerateTasksOpen", setGenerateTasksOpen);
                    if (setGenerateTasksOpen) {
                      setGenerateTasksOpen(true);
                    }
                  }}
                >
                  {
                    <>
                      Aufgaben generieren <Assignment />
                    </>
                  }
                </Button>
              </Tooltip>
            </>
          )}
        </div>
        <div
          style={{
            marginTop: "3.5em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {ocrProgress !== 0 &&
            ocrProgress !== 100 && ( //Damit es nicht angezeigt wird wenn der progress 0 ist
              <div style={{ marginBottom: "1em" }}>
                <div>Text wird eingelesen: {ocrProgress}%</div>
                <LinearProgress variant="determinate" value={ocrProgress} />
              </div>
            )}
          {document?.base64 &&
            (document.base64.includes("application/pdf") ||
              !document.base64.includes("image/")) && (
              <PdfViewer
                pdf={
                  document.base64.startsWith(`data:`)
                    ? document?.base64
                    : `data:${
                        document ? document.fileEnding : "application/pdf"
                      };base64,${document?.base64}`
                }
              />
            )}
          {document?.base64 && document.base64.includes("image/") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={imageData}
                style={{ height: "60%", marginTop: "20%" }}
              />
            </div>
          )}
        </div>

        <CreateBillModal
          open={open}
          setOpen={setOpen}
          handleSubmit={handleCreateBillAndDownload}
        />
      </Card>
    </>
  );
};

export default BillPdfViewerCard;
