import { useContacts } from "../Contexts/ContactsContext/ContactsProvider";
import { useUser } from "../Contexts/UserContext/UserContext";

export const useOrganizationTax = () => {
  const { companies } = useContacts();
  const { user } = useUser();

  let organizationTax = 0;

  const userCompany = companies?.find(
    (company) => company.id === user?.organizationId
  );

  userCompany?.kleinunternehmer ? organizationTax = 0 : organizationTax = 20;

  return {
    organizationTax
  }

};
