import { Switch, Tooltip } from "@mui/material";
import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IProductDTO,
  useProducts,
} from "../../Contexts/ProductContext/ProductProvider";
import useAuthConnection from "../../Hooks/useAuthConnection";
import {
  BillingPositionOrginType,
  IBillingPosition,
} from "../../Interfaces/Bill";
import { IServiceDTO } from "../../Interfaces/Service";
import Android12Switch from "../Buttons/Switch/Android12Switch";
import DateInputField from "../InputField/DateInputField";
import InputField from "../InputField/InputField";
import MultiLineInputField from "../InputField/MultiLineInputField";
import SearchableSelect from "../InputField/SearchableSelect";
import FormRow from "./FormUtils/FormRow";
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored";
import { useBills } from "../../Contexts/BillContext/BillProvider";

interface props {
  object: IBillingPosition;
  setObject: Dispatch<SetStateAction<IBillingPosition>>;
  services?: IServiceDTO[];
  setServices?: Dispatch<SetStateAction<IServiceDTO[]>>;
  product?: IProductDTO;
  setProduct?: Dispatch<SetStateAction<IProductDTO | undefined>>;
  error?: any;
}

const CreateBillingPosition = ({
  error,
  object,
  setObject,
  services,
  setServices,
  product,
  setProduct,
}: props) => {
  const [service, setService] = useState<IServiceDTO | undefined>(undefined);

  const [tempServices, setTempServices] = useState(services ?? []);

  const { products, updateProduct } = useProducts();
  const { units } = useBills();
  const connection = useAuthConnection();

  useEffect(() => {
    if (
      setProduct &&
      object.originType === BillingPositionOrginType.Product &&
      object.originId > 0
    ) {
      setProduct(products.find((temp) => temp.id === object.originId));
    }
    if (
      object.originType === BillingPositionOrginType.Service &&
      object.originId > 0
    ) {
      connection
        .get(`/service/${object.originId}`)
        .then((res: AxiosResponse) => {
          setService(res.data);
          setTempServices([...tempServices, res.data]);
        });
    }
  }, [object]);

  const serviceToBillingPosition = (val: any) => {
    if (val > 0 && services) {
      val = services.find((temp) => temp.id === val);
      setService((old: any) => val);

      let netto = 0.0;
      let hours = 0.0;
      if (val.employeeDatas) {
        val.employeeDatas.forEach((employeeData: any) => {
          let duration = diff_hours(
            new Date(employeeData.to),
            new Date(employeeData.from)
          );
          hours += duration;
          netto += duration * employeeData.hourlyRate;
        });
      }

      setObject({
        ...object,
        title: val.title,
        description: val.description,
        originId: val.id,
        netto: netto / hours,
        quantity: hours,
        unitId: 1,
      });
    }
  };

  function diff_hours(dt2: Date, dt1: Date) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;

    return Math.abs(Math.ceil(diff / 0.25) * 0.25);
  }

  const productToBillingPosition = (val: number) => {
    if (val > 0 && products) {
      let tempObj: IProductDTO | undefined = products.find(
        (temp) => temp.id === val
      );
      if (setProduct && tempObj) {
        setProduct(tempObj);
      }
      setObject({
        ...object,
        title: tempObj?.title ?? "",
        description: tempObj?.description ?? "",
        originId: tempObj?.id ?? 0,
        originType: BillingPositionOrginType.Product,
        netto: parseFloat(tempObj?.salesPrice + "") ?? 0,
        quantity: 1,
        tax: tempObj?.ust ?? 0,
      });
    }
  };

  return (
    <form className="form">
      {((tempServices &&
        tempServices!.length > 0 &&
        (service !== undefined || product === undefined)) ||
        (tempServices &&
          object.originType === BillingPositionOrginType.Service &&
          object.originId > 0)) && (
        <div className="form-row-label">
          <span className="label">Leistung:</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchableSelect
              width="100%"
              titleKey="title"
              valueKey="id"
              options={tempServices}
              value={service?.id}
              setValue={(val: any) => serviceToBillingPosition(val)}
              placeholder="Leistung auswählen"
            />
          </div>
        </div>
      )}
      {((products &&
        products!.length > 0 &&
        (service === undefined || product !== undefined)) ||
        (products &&
          object.originType === BillingPositionOrginType.Product &&
          object.originId > 0)) && (
        <div className="form-row-label">
          <span className="label">Produkt:</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchableSelect
              width="100%"
              titleKey="title"
              valueKey="id"
              options={products}
              value={product?.id}
              setValue={(val: any) => productToBillingPosition(val)}
              placeholder="Produkt auswählen"
            />
          </div>
        </div>
      )}
      <FormRow
        label="Bezeichnung"
        value={object.title}
        onChange={(title) => setObject((old) => ({ ...old, title }))}
        placeholder="Bezeichnung eingeben"
        type="text"
        error={error.titleError}
        errorMessage={InputFieldErrorMessages.TitleError}
      />
      <FormRow
        label="Beschreibung"
        value={object?.description}
        onChange={(description) =>
          setObject((old) => ({ ...old, description }))
        }
        placeholder="Beschreibung..."
        type="multiline"
      />
      {(service !== undefined ||
        object.originType === BillingPositionOrginType.Service) && (
        <>
          <div className="form-row-label">
            <span className="label">Start:</span>
            <DateInputField
              value={object.from}
              onChangeHandler={(val: any) =>
                setObject((prev: IBillingPosition) => ({ ...prev, from: val }))
              }
            />
          </div>
          <div className="form-row-label">
            <span className="label">Ende:</span>
            <DateInputField
              value={object.to}
              onChangeHandler={(val: any) =>
                setObject((prev: IBillingPosition) => ({ ...prev, to: val }))
              }
            />
          </div>
        </>
      )}
      <FormRow
        label="Netto"
        step="1"
        value={object.netto}
        onChange={(netto: number) =>
          setObject((old) => ({ ...old, netto: parseFloat(netto.toString()) }))
        }
        placeholder="Nettobetrag"
        type="number"
        error={error.nettoError}
        errorMessage={"Bitte geben Sie einen Nettopreis an"}
      />
      <FormRow
        label="Steuer"
        step="1"
        value={object.tax}
        onChange={(tax: number) =>
          setObject((old) => ({ ...old, tax: parseFloat(tax.toString()) }))
        }
        placeholder="Steuer"
        type="number"
        error={error.taxError}
        errorMessage={"Bitte geben Sie einen Steuersatz an"}
      />
      <div style={{ display: "flex", flexDirection: "row", gap: "4%" }}>
        <div style={{ width: "48%" }}>
          <FormRow
            label="Stückzahl"
            step=".25"
            value={object.quantity}
            onChange={(quantity) => {
              if (parseFloat(quantity) >= 0) {
                setObject((old) => ({
                  ...old,
                  quantity: parseFloat(quantity),
                }));
              }
            }}
            placeholder="Stückzahl"
            type="number"
            error={error.quantityError}
            errorMessage={"Bitte geben Sie eine Stückzahl an"}
          />
          {product?.count !== undefined &&
            product.count !== null &&
            (object?.quantity ?? 0) > product?.count && (
              <span className="label" style={{ color: "#FF9494" }}>
                Hinweis: Es sind nur {product?.count} Stück verfügbar.
              </span>
            )}
        </div>
        <div style={{ width: "48%" }}>
          <FormRow
            onChange={(unitId) => {
              setObject((old) => ({ ...old, unitId: unitId }));
            }}
            label="Einheit"
            type="select"
            placeholder="Einheit"
            value={object.unitId}
            selectOptions={{
              options: units,
              titleKey: "title",
              valueKey: "id",
              multiple: false,
            }}
          />
        </div>
      </div>
      <div className="form-row noselect">
        <span style={{ fontSize: "14px" }}>Rabatt</span>
        <span>
          <Android12Switch
            color="secondary"
            checked={
              object?.hasDiscount === undefined ? false : object.hasDiscount
            }
            onChange={(e, val) =>
              setObject((oldVal: IBillingPosition) => ({
                ...oldVal,
                hasDiscount: val,
              }))
            }
          />
        </span>
      </div>
      {object?.hasDiscount === true && (
        <>
          <div className="form-row noselect">
            <span style={{ fontSize: "14px" }}>Prozentual</span>
            <Switch
              color="secondary"
              checked={object?.discountType}
              onChange={(e, val) =>
                setObject((oldVal: IBillingPosition) => ({
                  ...oldVal,
                  discountType: !oldVal.discountType,
                }))
              }
            />
            <span style={{ fontSize: "14px" }}>Betrag</span>
          </div>
          <div className="form-row-label">
            <span className="label">Rabatt:</span>
            <InputField
              type="number"
              step=".1"
              value={object.discount}
              onChangeHandler={(discount) =>
                setObject((old) => ({ ...old, discount: parseFloat(discount) }))
              }
              placeholder={!object.discountType ? "Rabatt in %" : "Rabatt in €"}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default CreateBillingPosition;
