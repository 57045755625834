import React, { useTransition } from "react";
import PersistLogin from "./Components/Utils/PersistLogin";
import MUIThemeProvider from "./Contexts/Themes/MUIThemeProvider";
import { UserProvider } from "./Contexts/UserContext/UserContext";
import { SnackbarProvider } from "notistack";

import "./GlobalStyles/Utilities.css";
import { useSizeWithCallback } from "./Hooks/useSize";
import MainRouter from "./Navigation/MainRouter";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { pdfjs } from "react-pdf";

function App() {
  const root = document.documentElement;
  // Worker configuration (do this ONCE in your app, ideally in a shared file or at the app's root)
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  const [isPending, startTransition] = useTransition();

  useSizeWithCallback({
    target: root,
    callback: (entry) => {
      startTransition(() => {
        root.style.setProperty(
          "--page-height",
          entry.contentRect.height - 55 + "px"
        );
      });
    },
  });

  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        style={{
          borderRadius: "10px",
        }}
        autoHideDuration={3000}
      >
        <UserProvider>
          <PersistLogin>
            <MUIThemeProvider>
              <MainRouter />
            </MUIThemeProvider>
          </PersistLogin>
        </UserProvider>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
