import { useEffect, useState } from "react";
import BillDataCard from "../../Components/BillCards/BillDataCard";
import CardColumn from "../../Components/Card/CardColumn/CardColumn";

import { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CreateBillModal from "../../Components/BillCards/CreateBillModal";
import GenerateTasksDialog from "../../Components/Dialogs/GenerateTasksDialog/GenerateTasksDialog";
import BillPdfViewerCard from "../../Components/PdfViewerCard/BillPdfViewerCard";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { downloadFile } from "../../Contexts/DocumentContext/DocumentContext";
import { useUser } from "../../Contexts/UserContext/UserContext";
import useAuthConnection from "../../Hooks/useAuthConnection";
import { IBillDTO, IBillingPosition } from "../../Interfaces/Bill";
import { BillProvider, useBillContext } from "./BillContext";

interface props {
  type?: "Offer" | "Bill" | "Receipt";
}

const CreateBill = ({ type = "Bill" }: props) => {
  const { id } = useParams();
  const connection = useAuthConnection();
  const navigate = useNavigate();

  const {
    bills,
    addBill,
    billPrefixes,
    updateBill,
    defaultBill,
    markBillCreated,
  } = useBills();

  const { companies } = useContacts();
  const { user } = useUser();

  const [object, setObject] = useState<IBillDTO | undefined>({
    ...defaultBill,
  });
  //const [document, setDocument] = useState<any>(undefined);
  const { document, setDocument } = useBillContext();
  const [billingPositions, setBillingPositions] = useState<
    Array<IBillingPosition>
  >([]);
  const [billPrefix, setBillPrefix] = useState<any>();
  const [billnumber, setBillnumber] = useState<number | undefined>();

  const [open, setOpen] = useState<boolean>(false);

  const isOffer = type === "Offer";
  const isReceipt = type === "Receipt";

  useEffect(() => {
    const bill = bills.find(
      (temp) =>
        temp.bill.prefix === billPrefix?.id &&
        temp.bill.billNumber === billnumber
    );

    if (bill?.bill) {
      setBillingPositions(bill.billingPositions);
    }
  }, [bills]);

  useEffect(() => {
    if (object?.billNumber != null) {
      connection
        .get(`/document/download64/${object?.document}`)
        .then((res: AxiosResponse) => {
          setDocument(res.data);
        })
        .catch(() => {
          generatePdf(object);
        });
    }
  }, [object?.billNumber]);

  useEffect(() => {
    //Refresh Bill-Data
    if (id !== undefined) {
      if (id.includes("-")) {
        const lastHyphenIndex = id.lastIndexOf("-"); //gets index of the last occurence of -

        const split = id.split("-");
        let tempPrefix = "";
        let tempBillNumber = 0;
        split.forEach((string, index) => {
          if (index < split.length - 2) {
            tempPrefix += string + "-";
          } else if (index < split.length - 1) {
            tempPrefix += string;
          } else {
            tempBillNumber = parseInt(string);
          }
        });

        const prefix = billPrefixes.find((temp) => temp.prefix === tempPrefix);
        const bill = bills.find(
          (temp) =>
            temp.bill.prefix === prefix?.id &&
            temp.bill.billNumber === tempBillNumber
        );

        if (bill?.bill) {
          setBillnumber(tempBillNumber);
          setBillPrefix(prefix);

          setObject(bill?.bill);
          setBillingPositions(bill.billingPositions);

          connection
            .get(`/document/download64/${bill.bill.document}`)
            .then((res: AxiosResponse) => {
              setDocument(res.data);
            })
            .catch(() => {
              generatePdf(bill.bill);
            });
        }
      }
    } else {
      setObject({ ...defaultBill });
      setBillingPositions([]);
      setDocument({ base64: "" });

      const createBill = async () => {
        let result: any;

        if (addBill) {
          result = await addBill({
            billingPositions: [],
            bill: {
              ...defaultBill,
              isOffer: isOffer,
              generatePDF: !isReceipt,
              date: new Date(),
              isReceipt: isReceipt,
              isCreated: false,
              isSend: false,
            },
          });

          if (isReceipt) {
            navigate(
              `/rechnungswesen/belege/bearbeiten/${result.bill.billPrefix}-${result.bill.billNumber}`,
              { replace: true }
            );
          } else if (isOffer) {
            navigate(
              `/rechnungswesen/angebote/bearbeiten/${result.bill.billPrefix}-${result.bill.billNumber}`,
              { replace: true }
            );
          } else {
            navigate(
              `/rechnungswesen/rechnungen/bearbeiten/${result.bill.billPrefix}-${result.bill.billNumber}`,
              { replace: true }
            );
          }
        }

        if (
          result !== null &&
          result?.id !== null &&
          result?.id !== 0 &&
          !isReceipt &&
          generatePdf
        ) {
          generatePdf();
        }
      };
      createBill();

      //This creates the Bill automatically
      setObject((prev: any) => ({ ...prev, incoming: true, isCreated: false }));
    }
  }, [id]);

  const generatePdf = (bill?: IBillDTO) => {
    return new Promise(function (resolve, reject) {
      let x = bill ?? object;
      if (
        x?.id !== undefined &&
        x.id !== null &&
        x.id !== 0 &&
        x.isReceipt === false
      ) {
        connection
          .post(`/bill/generatepdf`, { id: x?.id })
          .then((res: AxiosResponse) => {
            setDocument(res.data);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  const handleCreateBillAndDownload = () => {
    setOpen(false);

    generatePdf().then((res: any) => {
      download();
    });

    if (markBillCreated) {
      markBillCreated({ ...object, isOffer: false });
      setObject((old) => ({ ...old, isCreated: true }));
    }
  };

  const download = () => {
    if (object?.document !== undefined) {
      downloadFile(
        {
          id: object.document,
          title: object.billPrefix + "-" + object.billNumber + ".pdf",
        },
        `/document/download/${object?.document}`,
        null
      );
    }
  };

  const [file, setFile] = useState<any>();
  const [generateTasksOpen, setGenerateTasksOpen] = useState(false);

  return (
    <>
      <CardColumn width="50%" height="100%">
        <BillDataCard
          width="100%"
          height="100%"
          billId={id}
          object={object}
          setObject={setObject}
          user={user ?? undefined}
          billPrefixes={billPrefixes}
          companies={companies}
          connection={connection}
          generatePdf={generatePdf}
          billingPositions={billingPositions}
          setBillingPositions={setBillingPositions!}
          type={type}
        />
      </CardColumn>
      <CardColumn width="50%" height="100%">
        <BillPdfViewerCard
          type={type}
          object={object}
          setObject={setObject}
          document={document}
          setDocument={setDocument}
          billingPositions={billingPositions}
          setGenerateTasksOpen={setGenerateTasksOpen}
          setBillingPositions={setBillingPositions}
          setFile={setFile}
        />
      </CardColumn>
      <GenerateTasksDialog
        bill={object}
        billingPositions={billingPositions}
        setBillingPositions={setBillingPositions}
        onClose={() => {
          setGenerateTasksOpen(false);
        }}
        open={generateTasksOpen}
      />
      <CreateBillModal
        open={open}
        setOpen={setOpen}
        handleSubmit={handleCreateBillAndDownload}
      />
    </>
  );
};

export default CreateBill;
