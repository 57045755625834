import { Dayjs } from "dayjs";
import { useCompanyData } from "../Contexts/CompanyDataContext/CompanyDataProvider";

export interface IBillDTO {
  id?: number;
  organizationId?: number;
  organizationName?: string;
  customerId?: number;
  personId?: number;
  customerName?: string;
  prefix?: number;
  billPrefix?: string;
  billNumber?: number;
  document?: number;
  account?: number;
  positiveAmount?: boolean;
  isCreated?: boolean;
  isReceipt?: boolean;
  text?: string;
  textBefore?: string;
  textAfter?: string;
  date?: Date;
  from?: Date;
  to?: Date;
  netto?: number;
  incoming?: boolean;
  isPaid?: boolean;
  isSend?: boolean;
  note?: string;
  isOffer?: boolean;
  tasksGenerated?: boolean;
  paymentDate?: Date;
  generatePDF?: boolean;
  rounding?: number;
  subscriptionId?: number;
}

export const DiscountType = {
  Percentage: false,
  FixedValue: true,
};

export interface IBillingPosition {
  id?: number;
  billId?: number;
  isCreated?: boolean;
  title?: string;
  description?: string;
  netto?: number;
  brutto?: number;
  tax?: number;
  organizationId?: number;
  quantity?: number;
  from?: Dayjs | undefined;
  to?: Dayjs | undefined;
  hasDiscount?: boolean;
  discountType?: boolean;
  discount?: number;
  unitId?: number;
  originId: number;
  originType: BillingPositionOrginType;
  pricePerUnit?: number
}

export interface IBillingPositionDTO extends IBillingPosition {
  subPositions?: IBillingPositionDTO[];
  isSummaryPosition?: boolean;
}

export interface IBillListDTO {
  bill: IBillDTO;
  billingPositions: Array<IBillingPositionDTO>;
  file?: File;
  emailStatus?: 'delivered' | 'bounce' | 'defer' | 'block'
}

export interface IUnit {
  id: number;
  title: string;
}

export enum BillingPositionOrginType {
  None = "None",
  Product = "Product",
  Service = "Service",
  DeliveryNote = "DeliveryNote",
  Travel = "Travel",
}

const defaultBillingPosition: IBillingPosition = {
  id: 0,
  billId: 0,
  isCreated: false,
  title: "",
  description: "",
  brutto: 0,
  netto: 0,
  tax: 0,
  organizationId: 0,
  quantity: 1,
  from: undefined,
  to: undefined,
  hasDiscount: false,
  discountType: false,
  discount: 0,
  unitId: 0,
  originId: 0,
  originType: BillingPositionOrginType.None,
};

export const useDefaultBillingPosition = () => {
  const { companyData } = useCompanyData();

  return {
    ...defaultBillingPosition,
    tax: companyData.kleinunternehmer ? 0 : 20,
  };
};

export const parseBillEmailStatus = (emailStatus: string | undefined) => {
  switch (emailStatus) {
      case "delivered":
          return "(Zugestellt)";
      case "open":
          return "(Gesehen)";
      case "spam":
          return "(Im Spam)";
      case "dropped":
          return "(Unzustellbar)";
      case "deferred":
          return "(Unzustellbar)";
      case "bounced":
          return "(Unzustellbar)";
      default:
          return ""
  }
}
