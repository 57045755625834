import { SetStateAction, useEffect, useState } from "react";
import {
  IBillingPositionDTO,
  useDefaultBillingPosition,
} from "../../Interfaces/Bill";
import FormRow from "../Forms/FormUtils/FormRow";
import BillingPositionDiscountFormRow from "./BillingPositionDiscountFormRow";
import BillingPositionPreview from "./BillingPositionPreview";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import { useHandleUpdateSummaryPosition } from "../../redux/requestHandlers/BillingPosition.handler";

type EditSummaryPositionFormProps = {
  billId: number;
  initialBillingPosition?: IBillingPositionDTO;
  setDialogOpen: React.Dispatch<SetStateAction<boolean>>;
};
export default function SummaryPositionForm({
  billId,
  initialBillingPosition,
  setDialogOpen,
}: EditSummaryPositionFormProps) {
  const defaultBillingPosition = useDefaultBillingPosition();
  const [billingPosition, setBillingPosition] = useState<IBillingPositionDTO>(
    initialBillingPosition ?? defaultBillingPosition
  );

  const { handleUpdateSummaryPosition, isLoading, isSuccess } =
    useHandleUpdateSummaryPosition();
  const handleSubmit = async () => {
    await handleUpdateSummaryPosition({ ...billingPosition, billId: billId });
  };

  useEffect(() => {
    if (isSuccess && setDialogOpen) setDialogOpen(false);
  }, [isSuccess]);

  return (
    <div className="flex flex-col gap-2">
      <FormRow
        autofocus
        label="Bezeichnung"
        value={billingPosition.title}
        onChange={(title) => setBillingPosition((old) => ({ ...old, title }))}
        placeholder="Bezeichnung eingeben"
        type="text"
        //error={error.titleError}
        //errorMessage={InputFieldErrorMessages.TitleError}
      />
      <FormRow
        label="Beschreibung"
        value={billingPosition.description}
        onChange={(description) =>
          setBillingPosition((old) => ({ ...old, description }))
        }
        placeholder="Beschreibung..."
        type="multiline"
      />
      <div className="mt-4 mb-8">
        <BillingPositionDiscountFormRow
          billingPosition={billingPosition}
          setBillingPosition={setBillingPosition}
        />
      </div>
      <BillingPositionPreview billingPosition={billingPosition} />
      <CTAButton
        title={"Speichern"}
        isLoading={isLoading}
        onClickHandler={handleSubmit}
      />
    </div>
  );
}
