
import CreateBill from './CreateBill';


const CreateReceipt = () => {


  return (<>
    <CreateBill type='Receipt' />
  </>)
}

export default CreateReceipt