import { VerticalDivider } from "../../Divider/VerticalDivider";

type BillingPositionTaxProps = {
  tax: number | undefined;
  isSummaryPosition: boolean | undefined;
};
export default function BillingPositionTax({
  tax,
  isSummaryPosition
}: BillingPositionTaxProps) {
  return (
    !isSummaryPosition && (
      <>
        <VerticalDivider />
        <span>
          Steuer: {tax?.toFixed(2).replace(".", ",")}
          &nbsp;%
        </span>
      </>
    )
  );
}
