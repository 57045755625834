import React, { SetStateAction } from "react";
import { useTasks } from "../../Contexts/TaskContext/TaskContext";
import { useNavigate } from "react-router-dom";
import DeliveryNoteRow from "./DeliveryNoteRow";
import { useMobileView } from "../../Hooks/useMobileView";
import { AddRounded } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardFooter,
  CardFooterButton,
  CardHeader,
  CardTitle,
} from "../UI/CardNew";
import DeliveryNoteOverviewHeader from "./DeliveryNoteOverviewHeader";
import { IDeliveryNoteDTO } from "../../Pages/Services/LieferscheinErstellen";

type DeliveryNoteOverviewCardProps = {
    setClickedDeliveryNote?: React.Dispatch<SetStateAction<IDeliveryNoteDTO | undefined>>
}

function DeliveryNoteOverviewCard({setClickedDeliveryNote} : DeliveryNoteOverviewCardProps) {
  const { deliveryNotes } = useTasks();
  const navigate = useNavigate();

  const isMobile = useMobileView();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Lieferscheine</CardTitle>
      </CardHeader>
      <CardContent>
        {!isMobile && (
          <div>
            <DeliveryNoteOverviewHeader />
            {deliveryNotes?.map((deliveryNote: any) => (
              <DeliveryNoteRow
                deliveryNote={deliveryNote}
                key={deliveryNote.id}
                setClickedDeliveryNote={setClickedDeliveryNote}
              />
            ))}
          </div>
        )}

        {isMobile && (
          <>
            {deliveryNotes?.map((deliveryNote: any) => {
              return (
                <div className="flex flex-row hover:bg-slate-50">
                  <DeliveryNoteRow deliveryNote={deliveryNote} />
                </div>
              );
            })}
          </>
        )}
      </CardContent>
      <CardFooter>
        <CardFooterButton
          onClick={() => navigate("/aufgaben/lieferschein/erstellen")}
        >
          <AddRounded fontSize="small" />
          Lieferschein erstellen
        </CardFooterButton>
      </CardFooter>
    </Card>
  );
}

export default DeliveryNoteOverviewCard;
