import { IBillingPositionDTO } from "../../../Interfaces/Bill";
import { getSummaryPositionBruttoTotal } from "../BillingPosition.utils";

type BillingPositionBruttoProps = {
  isSummaryPosition: boolean | undefined;
  subpositions: IBillingPositionDTO[] | undefined;
  brutto: number | undefined;
};
export default function BillingPositionBrutto({
  isSummaryPosition,
  subpositions,
  brutto,
}: BillingPositionBruttoProps) {
  return !isSummaryPosition ? (
    <>
      <span>
        Brutto: {brutto?.toFixed(2).replace(".", ",")}
        &nbsp;€
      </span>
    </>
  ) : (
    <>
      <span>
        Brutto Gesamt:{" "}
        {getSummaryPositionBruttoTotal(isSummaryPosition, subpositions)
          ?.toFixed(2)
          .replace(".", ",")}
        &nbsp;€
      </span>
    </>
  );
}
