import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAuthQuery } from "../baseAuthQuery";
import { ITaskDTO } from "../../Interfaces/Task";
import { IAppointment } from "../../Interfaces/Appointment";
import { IHoliday } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider";

export interface CalendarEvents {
    scheduledTasks: ITaskDTO[]
    appointments: IAppointment[]
    acceptedHolidays: IHoliday[]
    requestedHolidays: IHoliday[]
}

export const CalendarApi = createApi({
    reducerPath: "CalendarApi",
    baseQuery: baseAuthQuery,
    tagTypes: ["CalendarEvents"],
    endpoints: (builder) => ({
        getAllCalendarEvents: builder.query<CalendarEvents, void>({
            query: () => ({
                url: "/calendar"
            }),
        })
    })
})

export const {
    useGetAllCalendarEventsQuery
} = CalendarApi