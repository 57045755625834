import { IBillingPositionDTO } from "../../../Interfaces/Bill";
import { getSummaryPositionNettoTotal } from "../BillingPosition.utils";

type BillingPositionNettoProps = {
  isSummaryPosition: boolean | undefined;
  subpositions: IBillingPositionDTO[] | undefined;
  netto: number | undefined;
};
export default function BillingPositionNetto({
  isSummaryPosition,
  subpositions,
  netto
}: BillingPositionNettoProps) {
  return (
    <span>
      {isSummaryPosition ? (
        <>
          Netto Gesamt:{" "}
          {getSummaryPositionNettoTotal(isSummaryPosition, subpositions)
            ?.toFixed(2)
            .replace(".", ",")}
          &nbsp;€
        </>
      ) : (
        <>
          {" "}
          Netto: {netto?.toFixed(2).replace(".", ",")}
          &nbsp;€
        </>
      )}
    </span>
  );
}
