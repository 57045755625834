import { AxiosResponse } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { ReactNode, createContext, useContext, useEffect, useReducer } from 'react'
import { errorHandler } from '../../Connection/BaseConnection'
import { groupBy } from '../../Hooks/Utilities/Utlitites'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { IAppointment, IAppointmentCategory, ICreateAppointment, ICreateAppointmentCategory } from '../../Interfaces/Appointment'
import { ITask, ITaskDTO } from '../../Interfaces/Task'
import { taskContext } from '../../Interfaces/TaskContext'
import { useUser } from '../UserContext/UserContext'
import { taskReducer } from './TaskReducer'
import { autoHideDurationDefault, autoHideDurationSuccess } from '../../Global/Variables'
import { IServiceDTO } from '../../Interfaces/Service'

interface IAPITask {
    task: ITask
    userIds: number[]

}

export interface IDeliveryNote {
    id?: number
    title?: string
    date?: Dayjs
    textBefore?: string
    textAfter?: string
    organizationId?: number
    customerOrganizationId?: number
    customerPersonId?: number
    signatureBase64?: string
    documentId?: number
    billId?: number
    projectId?: number
    number?: number
    authorId: number
}

export interface IDeliveryNoteWithPositions extends IDeliveryNote {
    positions?: Array<any>
    base64?: string
}

export interface IProjectDTO extends IProject {
    userIds: number[]
    areas?: Array<IProjectHasArea>
    services?: IServiceDTO[]
    tasks?: ITaskDTO[]
}

export interface IProjectHasArea {
    id: number, title: string, projectId: number
}

export interface IProject {
    id: number
    organizationId: number
    customerOrganizationId?: number
    title: string
    description?: string
    startDate?: Dayjs        //start of project
    scheduledDate?: Dayjs    //projected end of project
    finishedDate?: Dayjs     //actual end of project
    finished?: boolean
    abortedDate?: Dayjs
    aborted?: boolean
    deleted?: boolean
}

export const defaultProject: IProject = {
    id: 0,
    organizationId: 0,
    title: "",
    description: "",
    startDate: dayjs(),
    scheduledDate: undefined,
    finishedDate: undefined,
    finished: false,
    abortedDate: undefined,
    aborted: false
}

export const defaultProjectDTO: IProjectDTO = {
    ...defaultProject,
    areas: [],
    userIds: []
}

export enum AppointmentCategoryVisibilityTypes {
    Private = 0,
    Organization = 1
}

export enum DefaultAppointmentCategoryIds {
    OrganizationNoCategory = -1,
    Tasks = -2,
    Holiday = -3,
    PrivateNoCategory = -4
}

const TaskContext = createContext<taskContext>({
    tasks: [],
    allTasks: [],
    finishedTasks: [],
    tasksUnformated: [],
    allTasksUnformated: [],
    selectedTasks: [],
    deliveryNotes: [],
    appointments: [],
    monthIndex: 0,
    smallCalendarMonth: 0,
    selectedDay: dayjs(),
    appointmentCategories: [],
})

function TaskProvider({ children }: { children: ReactNode }) {

    const { user } = useUser()

    const connection = useAuthConnection()
    const { enqueueSnackbar, closeSnackbar } = useSnackBar()


    const dummyTasks: ITaskDTO[] = []

    //Zum Empfangen der Tasks
    const transformAPITasksIntoFrontendTasks = (tasksFromAPI: IAPITask[]) => {
        return tasksFromAPI.map((task) => {
            const { userIds } = task

            const taskToReturn = { ...task.task, userIds } as ITask

            return taskToReturn
        })
    }

    const addTask = async (newTask: ITaskDTO) => {
        let key = enqueueSnackbar("Aufgabe wird angelegt", { autoHideDuration: autoHideDurationDefault })

        //TODO Sollte die Response der API einfügen
        connection.post("/task/", { task: newTask, userIds: newTask.userIds ?? [] })
            .then((res: AxiosResponse) => {
                closeSnackbar(key);

                dispatch({
                    type: "ADD_TASK",
                    payload: { ...res.data.task, userIds: res.data.userIds }
                })
                enqueueSnackbar("Aufgabe erfolgreich angelegt", { variant: "success" });
            })
            .catch((error: any) => {
                errorHandler(error, key, enqueueSnackbar, closeSnackbar)
            })
    }

    const updateTask = (updatedTask: ITaskDTO) => {
        let key = enqueueSnackbar("Aufgabe wird geändert", { autoHideDuration: autoHideDurationDefault });

        connection.put('/task/', updatedTask)
            .then((res: AxiosResponse) => {
                closeSnackbar(key);

                dispatch({
                    type: "UPDATE_TASK",
                    payload: updatedTask
                })
                if (updatedTask.aborted === true) {
                    enqueueSnackbar("Aufgabe erfolgreich storniert", { variant: 'success' });
                } else {
                    enqueueSnackbar("Aufgabe erfolgreich geändert", { variant: 'success' });
                }
            })
            .catch((error: any) => {
                errorHandler(error, key, enqueueSnackbar, closeSnackbar)
            })

    }

    const finishTask = (task: ITaskDTO) => {
        let key = enqueueSnackbar("Aufgabe wird abgeschlossen", { autoHideDuration: autoHideDurationDefault })

        connection.put('/task/finish', task)
            .then((res: AxiosResponse) => {
                closeSnackbar(key)

                dispatch({
                    type: "FINISH_TASK",
                    payload: task
                })
                enqueueSnackbar("Aufgabe erfolgreich abgeschlossen", { variant: 'success' });
            })
            .catch((error: any) => {
                errorHandler(error, key, enqueueSnackbar, closeSnackbar)
            })
    }

    const deleteTask = (taskToDelete: ITaskDTO) => {
        let key = enqueueSnackbar("Aufgabe wird entfernt", { autoHideDuration: autoHideDurationDefault })

        connection.delete('/task', { data: taskToDelete })
            .then((res: AxiosResponse) => {
                closeSnackbar(key)

                enqueueSnackbar("Aufgabe erfolgreich entfernt", { variant: "success" })
            })
            .catch((error: any) => {
                errorHandler(error, key, enqueueSnackbar, closeSnackbar)
            })

        dispatch({
            type: "DELETE_TASK",
            payload: taskToDelete
        })
    }

    const fetchTasks = async () => {

        try {

            const { data } = await connection.get("/task/")

            dispatch({
                type: "SET_TASKS",
                payload: data
            })

        } catch (error) {
            dispatch({
                type: "SET_TASKS",
                payload: dummyTasks
            })
        }
    }

    const modifySelectedTask = (newTask: ITask, action: "add" | "remove") => {
        if (action == "add") {
            dispatch({
                type: "ADD_SELECTED_TASK",
                payload: newTask
            })
        } else {
            dispatch({
                type: "REMOVE_SELECTED_TASK",
                payload: newTask
            })
        }
    }

    const resetSelectedTasks = () => {
        dispatch({
            type: "RESET_SELECTED_TASKS",
            payload: null
        })
    }


    const fetchDeliveryNotes = () => {
        connection.get("/task/DeliveryNote")
            .then((res) => {
                dispatch({
                    type: "SET_DELIVERYNOTES",
                    payload: res.data ?? []
                })
            })
    }

    const addDeliveryNote = (deliveryNote: any) => {
        return new Promise(function (resolve, reject) {
            connection.post("/task/DeliveryNote", deliveryNote)
                .then((res) => {
                    dispatch({
                        type: "ADD_DELIVERYNOTE",
                        payload: res.data
                    })
                    resolve(res.data);
                })
                .catch(error => reject(error))
        })
    }

    const refreshDocument = (deliveryNote: any) => {
        return new Promise(function (resolve, reject) {
            connection.post("/task/DeliveryNote/refreshDocument", deliveryNote)
                .then((res) => {
                    /*dispatch({
                        type: "ADD_DELIVERYNOTE",
                        payload: res.data
                    })*/
                    resolve(res.data);
                })
                .catch(error => reject(error))
        })
    }

    const updateDeliveryNote = (deliveryNote: any) => {
        return new Promise(function (resolve, reject) {

            connection.put("/task/DeliveryNote", deliveryNote)
                .then((res) => {
                    dispatch({
                        type: "UPDATE_DELIVERYNOTE",
                        payload: res.data
                    })
                    resolve(res.data);
                })
                .catch(error => reject(error))
        });
    }

    const deleteDeliveryNote = (deliveryNote: any) => {
        return new Promise(function (resolve, reject) {
            connection.delete(`task/DeliveryNote?id=${deliveryNote.id}`)
                .then((res) => {
                    dispatch({
                        type: "DELETE_DELIVERYNOTE",
                        payload: deliveryNote
                    })
                    resolve(res.data);
                })
                .catch(error => reject(error))
        });
    }

    const setMonthIndex = (monthIndex: number) => {
        dispatch({
            type: "SET_MONTH_INDEX",
            payload: monthIndex
        })
    }

    const setSmallCalendarMonth = (smallCalendarMonth: number) => {
        dispatch({
            type: "SET_SMALL_CALENDAR_MONTH",
            payload: smallCalendarMonth
        })
    }

    const setSelectedDay = (selectedDay: any) => {
        dispatch({
            type: "SET_SELECTED_DAY",
            payload: selectedDay
        })
    }

    const addAppointmentCategory = (appointmentCategory: ICreateAppointmentCategory) => {
        let key = enqueueSnackbar("Kategorie wird erstellt", { autoHideDuration: autoHideDurationDefault })

        connection.post("/appointmentCategory/", appointmentCategory)
            .then((res: AxiosResponse) => {
                closeSnackbar(key);

                dispatch({
                    type: "ADD_APPOINTMENTCATEGORY",
                    payload: res.data
                })
                enqueueSnackbar("Kategorie erfolgreich erstellt", { variant: "success" });
            })
            .catch((error: any) => {
                errorHandler(error, key, enqueueSnackbar, closeSnackbar)
            })
    }

    const deleteAppointmentCategory = (appointmentCategory: IAppointmentCategory) => {
        let key = enqueueSnackbar("Termin wird gelöscht", { autoHideDuration: autoHideDurationDefault })

        connection.delete('/appointmentCategory', { data: appointmentCategory })
            .then((res: AxiosResponse) => {
                closeSnackbar(key)

                dispatch({
                    type: "DELETE_APPOINTMENTCATEGORY",
                    payload: appointmentCategory
                })
                enqueueSnackbar("Kategorie erfolgreich gelöscht", { variant: "success" })
            })
            .catch((error: any) => {
                errorHandler(error, key, enqueueSnackbar, closeSnackbar)
            })


    }

    const updateAppointmentCategory = (appointmentCategory: IAppointmentCategory) => {
        let x = enqueueSnackbar("Kategorie wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.put("/appointmentCategory", appointmentCategory)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                dispatch({
                    type: "UPDATE_APPOINTMENTCATEGORY",
                    payload: appointmentCategory
                })
                x = enqueueSnackbar("Kategorie erfolgreich bearbeitet", { variant: "success", autoHideDuration: autoHideDurationSuccess })


            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const addAppointment = (appointment: ICreateAppointment) => {
        let key = enqueueSnackbar("Termin wird erstellt", { autoHideDuration: autoHideDurationDefault })

        connection.post("/appointment/", appointment)
            .then((res: AxiosResponse) => {
                closeSnackbar(key);

                dispatch({
                    type: "ADD_APPOINTMENT",
                    payload: res.data
                })
                enqueueSnackbar("Termin erfolgreich erstellt", { variant: "success" });
            })
            .catch((error: any) => {
                errorHandler(error, key, enqueueSnackbar, closeSnackbar)
            })

    }

    const fetchAppointments = () => {
        connection.get("/appointment")
            .then((res) => {
                dispatch({
                    type: "SET_APPOINTMENTS",
                    payload: res.data ?? []
                })
            })
    }

    const deleteAppointment = (appointment: IAppointment) => {
        let key = enqueueSnackbar("Termin wird gelöscht", { autoHideDuration: autoHideDurationDefault })

        connection.delete('/appointment', { data: appointment })
            .then((res: AxiosResponse) => {
                closeSnackbar(key)

                enqueueSnackbar("Termin erfolgreich gelöscht", { variant: "success" })
            })
            .catch((error: any) => {
                errorHandler(error, key, enqueueSnackbar, closeSnackbar)
            })

        dispatch({
            type: "DELETE_APPOINTMENT",
            payload: appointment
        })
    }

    const updateAppointment = (appointment: IAppointment) => {
        let x = enqueueSnackbar("Termin wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.put("/appointment", appointment)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                x = enqueueSnackbar("Termin erfolgreich bearbeitet", { variant: "success", autoHideDuration: autoHideDurationSuccess })

                dispatch({
                    type: "UPDATE_APPOINTMENT",
                    payload: appointment
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const fetchAppointmentCategories = () => {
        connection.get("/appointmentCategory")
            .then((res) => {
                dispatch({
                    type: 'SET_APPOINTMENTCATEGORIES',
                    payload: res.data ?? []
                })
            })
    }

    const addDocumentToTask = (file: any, taskId: number, isBefore: boolean) => {
        let formData = new FormData();
        formData.append("formFile", file);

        let task: ITask = state.allTasksUnformated.find((temp: ITask) => temp.id === taskId);

        if (!task.documents) {
            task.documents = [];
        }

        connection.post(`/task/AddPictureToTask/${taskId}/${isBefore}`, formData)
            .then((res) => {
                task.documents = [...task.documents!, res.data];

                dispatch({
                    type: 'UPDATE_TASK',
                    payload: task
                })
            })
    }

    const deleteDocumentToTask = (taskId: number, documentId: number) => {
        let task: ITask = state.allTasksUnformated.find((temp: ITask) => temp.id === taskId);
        task.documents = task?.documents?.filter(temp => temp.id !== documentId);

        connection.delete(`/task/DeletePictureToTask/${taskId}/${documentId}`)
            .then((res) => {
                dispatch({
                    type: 'UPDATE_TASK',
                    payload: task
                })
            })
    }

    const getPicturesToTask = (taskId: number) => {
        let task: ITask = state.allTasksUnformated.find((temp: ITask) => temp.id === taskId);

        return new Promise(function (resolve, reject) {
            connection.get("/task/GetPicturesToTask/" + taskId)
                .then((res) => {

                    task.documents = res.data;
                    dispatch({
                        type: "UPDATE_TASK",
                        payload: task
                    })
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })
    }

    const addDocumentToArea = (file: any, areaId: number, isBefore: boolean, projectId: number) => {
        let formData = new FormData();
        formData.append("formFile", file);

        let project: ITask = state.projects.find((temp: ITask) => temp.id === projectId);

        if (!project.documents) {
            project.documents = [];
        }

        connection.post(`/project/AddPictureToArea/${areaId}/${isBefore}`, formData)
            .then((res) => {
                project.documents = [...project.documents!, res.data];

                dispatch({
                    type: 'UPDATE_PROJECT',
                    payload: project
                })
            })
    }

    const deleteDocumentToArea = (taskId: number, documentId: number) => {
        //TODO:
        //let task: ITask = state.allTasksUnformated.find((temp: ITask) => temp.id === taskId);
        //task.documents = task?.documents?.filter(temp => temp.id !== documentId);

        /*connection.delete(`/task/DeletePictureToArea/${taskId}/${documentId}`)
            .then((res) => {
                dispatch({
                    type: 'UPDATE_TASK',
                    payload: task
                })
            })*/
    }

    const getPicturesToArea = (taskId: number) => {
        //TODO:
        return new Promise(function (resolve, reject) { resolve("HEHE") });
        /*let task: ITask = state.allTasksUnformated.find((temp: ITask) => temp.id === taskId);

    return new Promise(function (resolve, reject) {
        connection.get("/task/GetPicturesToTask/" + taskId)
            .then((res) => {

                task.documents = res.data;
                dispatch({
                    type: "UPDATE_TASK",
                    payload: task
                })
                resolve(res.data)
            })
            .catch(error => reject(error))
    })*/
    }

    const addDeliveryNotePosition = async (deliveryNotePosition: any) => {
        return new Promise((resolve, reject) => {
            connection.post("/task/addposition", deliveryNotePosition)
                .then((res: AxiosResponse) => {
                    dispatch({
                        type: "ADD_DELIVERYNOTEPOSITION",
                        payload: res.data
                    });
                    resolve(res.data);
                })
                .catch((error: any) => {
                    errorHandler(error, null, enqueueSnackbar, closeSnackbar);
                    reject(error);
                })
        })
    }

    const updateDeliveryNotePosition = async (deliveryNotePosition: any) => {
        return new Promise((resolve, reject) => {
            connection.put("/task/updateposition", deliveryNotePosition)
                .then((res: AxiosResponse) => {
                    dispatch({
                        type: "UPDATE_DELIVERYNOTEPOSITION",
                        payload: res.data
                    })
                    resolve(res.data);
                })
                .catch((error: any) => {
                    errorHandler(error, null, enqueueSnackbar, closeSnackbar);
                })
        })
    }

    const deleteDeliveryNotePosition = async (deliveryNotePosition: any) => {
        return new Promise((resolve, reject) => {
            connection.delete(`/task/removeposition/${deliveryNotePosition.id}`)
                .then((res: AxiosResponse) => {
                    dispatch({
                        type: "DELETE_DELIVERYNOTEPOSITION",
                        payload: res.data
                    })
                    resolve(res.data);
                })
                .catch((error: any) => {
                    errorHandler(error, null, enqueueSnackbar, closeSnackbar);
                })
        })
    }

    //Komplexer State für den Kontext -> Good Practice für die Arbeit mit Context
    const [state, dispatch] = useReducer(taskReducer, {
        tasks: groupBy(dummyTasks, "organizationId"), //!REMOVE
        tasksUnformated: dummyTasks,
        defaultTask: {
            title: "",
            finished: false,
            finishedDate: null,
            description: "",
            organizationId: user?.organizationId!,
            scheduledDate: null,
            userIds: [],
            customerOrganizationId: null
        },
        selectedTasks: [],
        monthIndex: dayjs().month(),
        smallCalendarMonth: null,
        selectedDay: null,
        appointments: [],
        appointmentCategories: [],
        projects: []
    })

    useEffect(() => {
        fetchTasks();
        fetchAppointments();
        fetchAppointmentCategories()
        fetchDeliveryNotes();
        /* fetchProjects(); */
    }, [])

    return (
        <TaskContext.Provider
            value={{
                tasks: state.tasks,
                allTasks: state.unfinishedTasks,
                finishedTasks: state.finishedTasks,
                tasksUnformated: state.tasksUnformated,
                allTasksUnformated: state.allTasksUnformated,
                defaultTask: state.defaultTask,
                selectedTasks: state.selectedTasks,
                deliveryNotes: state.deliveryNotes,
                monthIndex: state.monthIndex,
                smallCalendarMonth: state.smallCalendarMonth,
                selectedDay: state.selectedDay,
                appointments: state.appointments,
                appointmentCategories: state.appointmentCategories,
                /* projects: state.projects, */
                addDeliveryNote,
                updateDeliveryNote,
                deleteDeliveryNote,
                addDeliveryNotePosition,
                updateDeliveryNotePosition,
                deleteDeliveryNotePosition,
                refreshDocument,
                modifySelectedTask,
                addTask,
                resetSelectedTasks,
                updateTask,
                finishTask,
                deleteTask,
                setMonthIndex,
                setSmallCalendarMonth,
                setSelectedDay,
                addAppointment,
                deleteAppointment,
                updateAppointment,
                addAppointmentCategory,
                deleteAppointmentCategory,
                updateAppointmentCategory,
                addDocumentToTask,
                deleteDocumentToTask,
                getPicturesToTask,
                /*                 addProject,
                                fetchProjects,
                                updateProject,
                                deleteProject, */
                addDocumentToArea,
                deleteDocumentToArea,
                getPicturesToArea
            }}
        >
            {children}
        </TaskContext.Provider>
    )
}

export default TaskProvider


export const useTasks = () => useContext(TaskContext)
