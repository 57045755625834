import { useState } from "react";
import { useGetDocumentByIdQuery } from "../../../redux/apiSlices/Document";
import PdfViewer from "../../../Components/PDF/all-pages";
import { CircularProgress } from "@mui/material";
type DeliveryNotePdfPreviewerProps = {
  documentId: number;
};

export default function DeliveryNotePdfPreviewer({
  documentId,
}: DeliveryNotePdfPreviewerProps) {
  const {
    data: document,
    isLoading,
    isFetching,
  } = useGetDocumentByIdQuery(documentId);

  // Check if pdfBytes exists
  return (
    <div className="h-full overflow-auto border rounded-lg flex justify-center">
      {document && !isLoading && !isFetching && (
        <PdfViewer
          pdf={
            document?.base64?.startsWith(`data:`)
              ? document
              : `data:application/pdf;base64,${document?.base64}`
          }
        />
      )}
      {isLoading ||
        (isFetching && (
          <div className="h-full w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        ))}
    </div>
  );

  return null; //TODO: placeholder
}
