import { useBills } from "../../../Contexts/BillContext/BillProvider";

type BillingPositionUnitProps = {
  quantity: number | undefined;
  unitId: number | undefined;
};
export default function BillingPositionUnit({
  quantity,
  unitId,
}: BillingPositionUnitProps) {
  const { units } = useBills();
  return (
    <span>
      Menge: {quantity?.toFixed(2).replace(".", ",")}
      &nbsp;
      {units.find((unit) => unit.id === unitId)?.title}
    </span>
  );
}
