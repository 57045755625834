import { CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IProductDTO } from "../../../Contexts/ProductContext/ProductProvider";
import useAuthConnection from "../../../Hooks/useAuthConnection";
import {
  BillingPositionOrginType,
  IBillDTO,
  IBillingPosition,
} from "../../../Interfaces/Bill";
import { IServiceDTO } from "../../../Interfaces/Service";
import CTAButton from "../../Buttons/CTAButton/CTAButton";
import Modal from "../../Dialog/Dialog";
import CreateBillingPosition from "../../Forms/CreateBillingPositionForm";
import { useBills } from "../../../Contexts/BillContext/BillProvider";

export type dialog = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  generatePdf: () => void;
  object?: IBillingPosition | undefined;
  billingPositions: IBillingPosition[];
  setBillingPositions: Dispatch<SetStateAction<IBillingPosition[]>>;
  bill: IBillDTO | undefined;
  openServices?: IServiceDTO[];
  setOpenServices?: Dispatch<SetStateAction<IServiceDTO[]>>;
  isOffer?: boolean;
};

export const validateBillingPositionForm = (
  setError: Dispatch<SetStateAction<any>>,
  billingPosition?: IBillingPosition
) => {
  let valid = true;

  if (billingPosition?.title === "" || billingPosition?.title === undefined) {
    setError((old: any) => ({ ...old, titleError: true }));
    valid = false;
  } else {
    setError((old: any) => ({ ...old, titleError: false }));
  }

  if (
    billingPosition?.netto === undefined ||
    billingPosition?.netto === 0 ||
    isNaN(billingPosition.netto)
  ) {
    setError((old: any) => ({ ...old, nettoError: true }));
    valid = false;
  } else {
    setError((old: any) => ({ ...old, nettoError: false }));
  }

  if (billingPosition?.tax === undefined || isNaN(billingPosition.tax)) {
    setError((old: any) => ({ ...old, taxError: true }));
    valid = false;
  } else {
    setError((old: any) => ({ ...old, taxError: false }));
  }

  return valid;
};

function EditBillingPositionDialog({
  open,
  setOpen,
  onClose,
  generatePdf,
  object,
  billingPositions,
  setBillingPositions,
  bill,
  openServices,
  setOpenServices,
  isOffer = false,
}: dialog) {
  const [exists, setExists] = useState<boolean>(object !== undefined);


  const { updateBillingPosition } = useBills();
  const [loading, setLoading] = useState(false);

  const [billingPosition, setBillingPosition] = useState<IBillingPosition>({
    billId: bill?.id ? bill?.id : 0,
    unitId: 0,
    tax: 20,
    quantity: 1,
    originId: 0,
    originType: BillingPositionOrginType.None,
  });

  useEffect(() => {
    if (bill) {
        setBillingPosition({
        ...object,
        billId: bill?.id,
        unitId: object?.unitId ?? 0,
        tax: object?.tax ?? 20,
        quantity: object?.quantity ?? 1,
        originId: 0,
        originType: BillingPositionOrginType.None,
      });
    }
  }, [bill]);

  const connection = useAuthConnection();

  const onSubmitEdit = () => {
    if (validateBillingPositionForm(setError, billingPosition)) {
      setLoading(true);
      if (exists === true) {
        connection
          .put("/bill/billingposition", billingPosition)
          .then((res: AxiosResponse) => {
            updateBillingPosition!(res.data);
            generatePdf();
          })
          .catch((reason: any) => {
            console.log(reason);
          });

        setBillingPositions(
          billingPositions.map((tempBP: IBillingPosition) => {
            if (tempBP.id === object?.id) {
              return billingPosition;
            }
            return tempBP;
          })
        );
        setLoading(false);
        setOpen(false);
      } else {
        connection
          .post("/bill/billingposition", billingPosition)
          .then((res: AxiosResponse) => {
            generatePdf();
            setBillingPositions((prev) => [...prev, res.data]);
            setLoading(false);
            setOpen(false);
            if (setOpenServices !== undefined && openServices !== undefined) {
              setOpenServices(
                openServices?.filter((service: IServiceDTO) => {
                  if (service.id === billingPosition?.originId && billingPosition.originType === BillingPositionOrginType.Service) {
                    return false;
                  }
                  return true;
                })
              );
            }
            setBillingPosition({
              ...object,
              billId: bill?.id,
              unitId: 0,
              tax: 20,
              quantity: 1,
              originId: object?.originId ?? 0,
              originType: object?.originType ?? BillingPositionOrginType.None,
            });
          })
          .catch((reason: any) => {
            setLoading(false);
            console.log(reason);
          });
      }
    }
  };

  const [error, setError] = useState<any>({});

  return (
    <Modal
      open={open}
      title={
        object !== undefined
          ? isOffer
            ? "Angebotsposition bearbeiten"
            : "Rechnungsposition bearbeiten"
          : isOffer
          ? "Angebotsposition erstellen"
          : "Rechnungsposition erstellen"
      }
      onClose={onClose ? onClose : () => setOpen(false)}
    >
      <CreateBillingPosition
        object={billingPosition}
        setObject={setBillingPosition}
        services={openServices}
        setServices={setOpenServices}

        error={error}
      />
      <div style={{ marginBottom: "2%" }}></div>
      <CTAButton
        title={
          loading ? (
            <CircularProgress size={10} />
          ) : exists ? (
            "Speichern"
          ) : (
            "Erstellen"
          )
        }
        onClickHandler={onSubmitEdit}
      />
    </Modal>
  );
}

export default EditBillingPositionDialog;
