import { useEffect, useState } from "react";
import DeliveryNoteOverviewCard from "../../../Components/DeliveryNote/DeliveryNoteOverviewCard";
import { useMobileView } from "../../../Hooks/useMobileView";
import { IDeliveryNoteDTO } from "../LieferscheinErstellen";
import DeliveryNotePdfPreviewer from "./DeliveryNotePdfPreviewer";

function DeliveryNote() {
  const isMobile = useMobileView();

  const [clickedDeliveryNote, setClickedDeliveryNote] =
    useState<IDeliveryNoteDTO>();

  return (
    <>
      {isMobile && <DeliveryNoteOverviewCard />}
      {!isMobile && (
        <div className="flex flex-row w-full gap-4">
          <div className="w-1/2">
            <DeliveryNoteOverviewCard setClickedDeliveryNote={setClickedDeliveryNote} />
          </div>

          <div className="w-1/2">
            <DeliveryNotePdfPreviewer documentId={clickedDeliveryNote?.documentId!}/>
          </div>
        </div>
      )}
    </>
  );
}

export default DeliveryNote;
