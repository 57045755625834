export default function DeliveryNoteOverviewHeader() {
  return (
    <div className="w-[100%] flex p-4">
      <div className="w-[40%] font-medium">Kunde</div>
      <div className="w-[20%] font-medium">Datum</div>
      <div
        lang="de"
        className="w-[10%] text-center break-words hyphens-auto font-medium"
      >
        Status
      </div>
      <div
        lang="de"
        className="w-[15%] text-center break-words hyphens-auto font-medium"
      >
        Nummer
      </div>
    </div>
  );
}
