import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TravelApi } from './apiSlices/Travel'
import { CalendarApi } from './apiSlices/Calendar'
import { BillPrefixApi } from './apiSlices/BillPrefix'
import { ProjectApi } from './apiSlices/Project'
import { TextModuleApi } from './apiSlices/TextModule'
import { ServiceApi } from './apiSlices/Service'
import { BillingPositionApi } from './apiSlices/BillingPosition.slice'
import { MailApi } from './apiSlices/Email'
import { DocumentApi } from './apiSlices/Document'

const rootReducer = combineReducers({
    [TravelApi.reducerPath]: TravelApi.reducer,
    [CalendarApi.reducerPath]: CalendarApi.reducer,
    [BillPrefixApi.reducerPath]: BillPrefixApi.reducer,
    [ProjectApi.reducerPath]: ProjectApi.reducer,
    [TextModuleApi.reducerPath]: TextModuleApi.reducer,
    [ServiceApi.reducerPath]: ServiceApi.reducer,
    [BillingPositionApi.reducerPath]: BillingPositionApi.reducer,
    [MailApi.reducerPath]: MailApi.reducer,
    [DocumentApi.reducerPath]: DocumentApi.reducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(TravelApi.middleware)
        .concat(CalendarApi.middleware)
        .concat(BillPrefixApi.middleware)
        .concat(ProjectApi.middleware)
        .concat(TextModuleApi.middleware)
        .concat(ServiceApi.middleware)
        .concat(MailApi.middleware)
        .concat(BillingPositionApi.middleware)
        .concat(DocumentApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch