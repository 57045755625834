import { Dispatch, SetStateAction } from "react";
import { useProducts } from "../../Contexts/ProductContext/ProductProvider";
import {
  IBillingPositionDTO,
  useDefaultBillingPosition
} from "../../Interfaces/Bill";
import FormRow from "../Forms/FormUtils/FormRow";
import { convertProductToBillingPosition } from "./BillingPosition.utils";

type BillingPositionProductSelectProps = {
  billingPosition: IBillingPositionDTO;
  setBillingPosition: Dispatch<SetStateAction<IBillingPositionDTO>>;
};
export default function BillingPositionProductSelect({
  billingPosition,
  setBillingPosition,
}: BillingPositionProductSelectProps) {
  const { products } = useProducts();
  const defaultBillingPosition = useDefaultBillingPosition();
  const handleProductSelectChange = (id: number) => {
    const product = products.find((product) => product.id === id);

    if (product) {
      let billingPosition: IBillingPositionDTO =
        convertProductToBillingPosition(product);
      setBillingPosition(billingPosition);
    } else {
      setBillingPosition(defaultBillingPosition);
    }
  };

  return (
    <FormRow
      label="Produkt auswählen"
      placeholder="Produkt auswählen"
      value={billingPosition.originId}
      onChange={handleProductSelectChange}
      type="select"
      selectOptions={{
        titleKey: "title",
        valueKey: "id",
        options: products ?? [],
        multiple: false,
      }}
    />
  );
}
