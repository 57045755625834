import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IBillingPosition,
  IBillingPositionDTO,
  useDefaultBillingPosition,
} from "../../Interfaces/Bill";
import {
  useHandleAddBillingPosition,
  useHandleUpdateBillingPosition,
} from "../../redux/requestHandlers/BillingPosition.handler";
import { useBillContext } from "../../Pages/Buchhaltung/BillContext";

export const useHandleBillingDetailsChange = (
  billingPosition: IBillingPosition,
  setBillingPosition: Dispatch<SetStateAction<IBillingPosition>>
) => {
  const [lastEdited, setLastEdited] = useState({
    bruttoEdited: false,
    nettoEdited: true,
  });
  const handleBruttoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let oldTax: number = Number(billingPosition.tax);
    let bruttoNew =
      event.target.value === ""
        ? undefined
        : parseFloat(Number(event.target.value).toFixed(2));

    const nettoNew = parseFloat(
      ((bruttoNew ?? 0) * (1 - oldTax / 100)).toFixed(2)
    );
    setBillingPosition((old) => ({
      ...old,
      brutto: bruttoNew,
      netto: nettoNew,
    }));
    setLastEdited((old) => ({
      ...old,
      bruttoEdited: true,
      nettoEdited: false,
    }));
  };

  const handleNettoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let oldTax: number = Number(billingPosition.tax);
    let newNetto =
      event.target.value === ""
        ? undefined
        : parseFloat(Number(event.target.value).toFixed(2));

    const newBrutto = parseFloat(
      ((newNetto ?? 0) * (1 + oldTax / 100)).toFixed(2)
    );
    setBillingPosition((old) => ({
      ...old,
      netto: newNetto,
      brutto: newBrutto,
    }));
    setLastEdited((old) => ({
      ...old,
      bruttoEdited: false,
      nettoEdited: true,
    }));
  };

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newTax = parseFloat(event.target.value) || 0;
    newTax = parseFloat(newTax.toFixed(2));
    if (newTax < 0) newTax = 0;
    if (newTax > 100) newTax = 100;

    let oldBrutto: number = Number(billingPosition.brutto);
    let oldNetto: number = Number(billingPosition.netto);
    if (lastEdited.nettoEdited) {
      setBillingPosition((old) => ({
        ...old,
        tax: newTax,
        brutto: parseFloat((oldNetto * (1 + newTax / 100)).toFixed(2)),
      }));
      return;
    }

    if (lastEdited.bruttoEdited) {
      setBillingPosition((old) => ({
        ...old,
        tax: newTax,
        netto: parseFloat((oldBrutto * (1 - newTax / 100)).toFixed(2)),
      }));
      return;
    }

    //default
    setBillingPosition((old) => ({
      ...old,
      tax: newTax,
      netto: parseFloat((oldBrutto * (1 - newTax / 100)).toFixed(2)),
    }));
  };

  return {
    handleBruttoChange,
    handleNettoChange,
    handleTaxChange,
  };
};

export const useBillingPositionForm = (
  initialBillingPosition: IBillingPositionDTO | undefined,
  billId: number,
  setDialogOpen: Dispatch<SetStateAction<boolean>> | undefined
) => {
  const defaultBillingPosition = useDefaultBillingPosition();
  const [billingPosition, setBillingPosition] = useState<IBillingPositionDTO>(
    initialBillingPosition ?? defaultBillingPosition
  );

  const [titleError, setTitleError] = useState<boolean>(false);

  const {
    handleAddBillingPosition,
    isLoading: isAddBillingPositionLoading,
    isSuccess: isAddBillingPositionSuccess,
  } = useHandleAddBillingPosition();
  const {
    handleUpdateBillingPosition,
    isLoading: isUpdateBillingPositionLoading,
    isSuccess: isUpdateBillingPositionSuccess,
  } = useHandleUpdateBillingPosition();
  const isLoading =
    isAddBillingPositionLoading || isUpdateBillingPositionLoading;
  const isSuccess =
    isAddBillingPositionSuccess || isUpdateBillingPositionSuccess;
  const isEditMode = !!initialBillingPosition;
  const { generatePdf } = useBillContext();
  const handleSubmit = async () => {
    if (!billingPosition.title || billingPosition.title.length === 0) {
      setTitleError(true);
      return;
    } else {
      setTitleError(false);
    }
    if (isEditMode) {
      await handleUpdateBillingPosition(billingPosition);
      generatePdf(billId);
    } else {
      await handleAddBillingPosition({ ...billingPosition, billId: billId });
      generatePdf(billId);
    }
  };

  useEffect(() => {
    if (isSuccess && setDialogOpen) setDialogOpen(false);
  }, [isSuccess]);

  return {
    billingPosition,
    setBillingPosition,
    handleSubmit,
    isLoading,
    isEditMode,
    titleError,
  };
};
