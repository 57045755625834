import { useSnackbar } from "notistack";
import { useDeleteTravelMutation } from "../apiSlices/Travel";
import { ITravel } from "../../Interfaces/Travel";
import { RTKQueryErrorHandler } from "./utils";
import { ServiceApi } from "../apiSlices/Service";

export const useHandleDeleteTravel = () => {
  const [deleteTravel, { isSuccess, isLoading, isError, error }] =
    useDeleteTravelMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleDeleteTravel = async (travel: ITravel) => {
    let x = enqueueSnackbar("Fahrt wird gelöscht", { variant: "default" });

    try {
      const result = await deleteTravel(travel).unwrap();
      if (travel.serviceId) {
        console.log(
          "deleteTravelHandler serviceId of travel:",
          travel.serviceId
        );
      }

      closeSnackbar(x);
      enqueueSnackbar("Fahrt erfolgreich geloescht", { variant: "success" });
      return result;
    } catch (err) {
      closeSnackbar(x);
      const errorMessage = RTKQueryErrorHandler(err);
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return {
    handleDeleteTravel,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};
