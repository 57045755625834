import { Paid, LocalShipping } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import MoreOptionsButton from "../Buttons/MoreOptionsButton/MoreOptionsButton";
import { useTasks } from "../../Contexts/TaskContext/TaskContext";
import { useMobileView } from "../../Hooks/useMobileView";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { IDeliveryNoteDTO } from "../../Pages/Services/LieferscheinErstellen";

interface IDeliveryNoteRow {
  deliveryNote: IDeliveryNoteDTO;
  setClickedDeliveryNote?: React.Dispatch<
    React.SetStateAction<IDeliveryNoteDTO | undefined>
  >;
}

function DeliveryNoteRow({
  deliveryNote,
  setClickedDeliveryNote,
}: IDeliveryNoteRow) {
  dayjs.locale("de-DE");
  const { companies } = useContacts();
  const { deleteDeliveryNote } = useTasks();
  const navigate = useNavigate();

  const isMobile = useMobileView();

  const options = [
    {
      text: "Bearbeiten",
      function: () => {
        navigate("/aufgaben/lieferschein/erstellen?id=" + deliveryNote.id);
      },
    },
    {
      text: "Löschen",
      function: () => {
        deleteDeliveryNote(deliveryNote);
      },
    },
  ];

  const companyName = companies.find(
    (company) => company.id === deliveryNote.customerOrganizationId
  )?.name;

  return (
    <>
      {!isMobile && (
        <div
          onClick={() => {
            if (setClickedDeliveryNote) setClickedDeliveryNote(deliveryNote);
          }}
          className="flex w-full p-4 hover:bg-slate-50 items-center"
        >
          <div className="w-[40%] truncate" title={companyName}>
            {companyName ?? <div className="italic">Kein Kunde zugewiesen</div>}
          </div>
          <div lang="de" className="w-[20%]">
            {dayjs(deliveryNote.date).format("DD.MM.YYYY, HH:mm")}
          </div>
          <div className="w-[10%] flex justify-center">
            {deliveryNote.billId !== null ? (
              <Tooltip title="Rechnung erstellt">
                <Paid style={{ color: "green" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Lieferschein erstellt">
                <LocalShipping />
              </Tooltip>
            )}
          </div>
          <div className="w-[15%] flex justify-center">
            {deliveryNote.number ?? "-"}
          </div>
          <div className="place-self-end">
            <MoreOptionsButton options={options} />
          </div>
        </div>
      )}

      {isMobile && (
        <div className="flex w-[100%] p-2 border-b-2 border-b-slate-200">
          <div className="flex flex-col w-[80%]">
            <div className="flex flex-row justify-between">
              <div className="font-medium">Kunde</div>
              <div className="text-center">
                {
                  companies.find(
                    (company) =>
                      company.id === deliveryNote.customerOrganizationId
                  )?.name
                }
              </div>
            </div>

            <div className="flex flex-row justify-between">
              <div className="font-medium">Datum</div>
              <div lang="de" className="text-center">
                {dayjs(deliveryNote.date).format("DD.MM.YYYY, HH:mm")}
              </div>
            </div>

            <div className="flex flex-row justify-between">
              <div className="font-medium">Status</div>
              <div className="flex justify-center">
                {deliveryNote.billId !== null ? (
                  <Tooltip title="Rechnung erstellt">
                    <Paid style={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Lieferschein erstellt">
                    <LocalShipping />
                  </Tooltip>
                )}
              </div>
            </div>

            <div className="flex flex-row justify-between">
              <div className="font-medium">Nummer</div>
              <div className="flex justify-center">
                {deliveryNote.number ?? "-"}
              </div>
            </div>
          </div>
          <div className="flex w-[20%] self-center ml-4">
            <MoreOptionsButton options={options} />
          </div>
        </div>
      )}
    </>
  );
}

export default DeliveryNoteRow;
