import {
  BillingPositionOrginType,
  IBillingPositionDTO,
} from "../../Interfaces/Bill";
import { Input } from "../UI/Input";
import { Label } from "../UI/Label";
import { useHandleBillingDetailsChange } from "./BillingPositionForm.hooks";
import TravelBillingDetails from "./TravelBillingDetails";

type BillingPositionBillingDetailsFormRowProps = {
  billingPosition: IBillingPositionDTO;
  setBillingPosition: React.Dispatch<React.SetStateAction<IBillingPositionDTO>>;
};
export default function BillingPositionBillingDetailsFormRow({
  billingPosition,
  setBillingPosition,
}: BillingPositionBillingDetailsFormRowProps) {
  const { handleBruttoChange, handleNettoChange, handleTaxChange } =
    useHandleBillingDetailsChange(billingPosition, setBillingPosition);
  return (
    <>
      {billingPosition.originType === BillingPositionOrginType.Travel ? (
        <TravelBillingDetails
          billingPosition={billingPosition}
          setBillingPosition={setBillingPosition}
        />
      ) : (
        <div className="flex flex-row gap-2">
          <div>
            <Label>Netto</Label>
            <Input
              type="number"
              placeholder="Nettobetrag"
              onChange={handleNettoChange}
              value={billingPosition.netto}
              onFocus={(e) => e.target.select()}
            />
          </div>
          <div>
            <Label>Steuer</Label>
            <Input
              placeholder="Steuer"
              onChange={handleTaxChange}
              value={billingPosition.tax}
              onFocus={(e) => e.target.select()}
            />
          </div>
          <div>
            <Label>Brutto</Label>
            <Input
              type="number"
              placeholder="Bruttobetrag"
              onChange={handleBruttoChange}
              value={billingPosition.brutto}
              onFocus={(e) => e.target.select()}
            />
          </div>
        </div>
      )}
    </>
  );
}
