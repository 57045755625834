import { Dispatch, SetStateAction } from "react";
import {
  BillingPositionOrginType,
  IBillingPosition,
  useDefaultBillingPosition,
} from "../../Interfaces/Bill";
import { useGetAllTravelsQuery } from "../../redux/apiSlices/Travel";
import FormRow from "../Forms/FormUtils/FormRow";
import { convertTravelToBillingPosition } from "./BillingPosition.utils";
import { useOrganizationTax } from "../../Hooks/useOrganizationTax";

type BillingPositionTravelSelectProps = {
  billingPosition: IBillingPosition;
  setBillingPosition: Dispatch<SetStateAction<IBillingPosition>>;
};
export default function BillingPositionTravelSelect({
  billingPosition,
  setBillingPosition,
}: BillingPositionTravelSelectProps) {
  const { data: travels } = useGetAllTravelsQuery();
  const defaultBillingPosition = useDefaultBillingPosition();
  const {organizationTax} = useOrganizationTax();
  const handleTravelSelectChange = (id: number) => {
    if (!travels) return;

    const travel = travels.find((travel) => travel.id === id);

    if (travel) {
      let billingPosition: IBillingPosition =
        convertTravelToBillingPosition(travel, organizationTax);
      setBillingPosition(billingPosition);
    } else {
      setBillingPosition(defaultBillingPosition);
    }
  };

  return (
    <FormRow
      label="Fahrt auswählen"
      placeholder="Fahrt auswählen"
      value={billingPosition.originId}
      onChange={handleTravelSelectChange}
      type="select"
      selectOptions={{
        titleKey: "title",
        valueKey: "id",
        options: travels ?? [],
        multiple: false,
      }}
    />
  );
}
